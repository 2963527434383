import { immerable } from "immer";

const NORMAL = "normal";
type NORMAL = typeof NORMAL;

const ADMIN = "admin";
type ADMIN = typeof ADMIN;

const CREATOR = "creator";
type CREATOR = typeof CREATOR;

type Type = NORMAL | ADMIN | CREATOR;

export class AccountType {
  static get Normal(): AccountType {
    return new AccountType(NORMAL);
  }

  static get Admin(): AccountType {
    return new AccountType(ADMIN);
  }

  static get Creator(): AccountType {
    return new AccountType(CREATOR);
  }

  static from(type: string | AccountType): AccountType {
    if (type instanceof AccountType) {
      return type;
    }

    switch (type) {
      case ADMIN: {
        return AccountType.Admin;
      }
      case CREATOR: {
        return AccountType.Creator;
      }
      default: {
        return AccountType.Normal;
      }
    }
  }

  readonly [immerable] = true;

  private constructor(private readonly type: Type) {}

  get isNormal(): boolean {
    return this.type === NORMAL;
  }

  get isAdmin(): boolean {
    return this.type === ADMIN;
  }

  get isCreator(): boolean {
    return this.type === CREATOR;
  }

  toString(): string {
    return this.type;
  }
}

import type { Reducer } from "react";
import type { Action, ActionList, InternalAction } from "./combineReducers";

export interface InternalStore<S, A extends Action<any> | ActionList<any>> {
  readonly getState: GetState<S>;
  readonly dispatch: Dispatch<A>;
  readonly initialize: (initialize: GetState<S>) => void;
  readonly subscribe: (callback: Callback) => Unsubscriber;
  readonly transaction: {
    (callback: (dispatch: Dispatch<A>, getState: GetState<S>) => void): void;
    (callback: (dispatch: Dispatch<A>, getState: GetState<S>) => Promise<void>): Promise<void>;
  };
}

type Callback = () => void;

export type Unsubscriber = () => void;

export type GetState<S> = () => S;

export type Dispatch<A extends Action<any> | ActionList<any>> = (action: A) => void;

const createInitialAction = (): InternalAction<any> => ({
  type: "InitialStore.initialize",
  payload: [],
  namespace: "InitialStore",
  key: "initialize"
});

export const createInitialStore = <S, A extends Action<any> | ActionList<any>>(reduce: Reducer<S, A>): InternalStore<S, A> => {
  const callbackSet = new Set<Callback>();
  let state: null | S = null;

  const publish = (): void => {
    for (const callback of callbackSet) {
      callback();
    }
  };

  const getState = (): S => {
    if (state === null) {
      state = reduce({} as S, (createInitialAction() as unknown) as A);
    }
    return state;
  };
  const dispatch = (action: A): void => {
    const previous = getState();
    const next = reduce(previous, action);
    if (previous !== next) {
      state = next;
      publish();
    }
  };
  const initialize = (initialize: () => S): void => {
    if (state === null) {
      state = reduce(initialize(), (createInitialAction() as unknown) as A);
      publish();
    }
  };
  const subscribe = (callback: Callback): Unsubscriber => {
    callbackSet.add(callback);
    return (): void => {
      callbackSet.delete(callback);
    };
  };
  function transaction(callback: (dispatch: Dispatch<A>, getState: GetState<S>) => void): void;
  function transaction(callback: (dispatch: Dispatch<A>, getState: GetState<S>) => Promise<void>): Promise<void>;
  function transaction(callback: (dispatch: Dispatch<A>, getState: GetState<S>) => void | Promise<void>): void | Promise<void> {
    const previous = getState();
    let state = previous;

    const _publish = (): void => {
      if (previous !== state) {
        publish();
      }
    };

    const promise = callback(
      action => {
        state = reduce(state, action);
      },
      () => state
    );
    if (promise instanceof Promise) {
      return promise.then(_publish);
    }

    _publish();
  }

  return { getState, dispatch, initialize, subscribe, transaction };
};

import { configs } from "configs";
import { KeyInfo } from "lib/key-info";

export const disableDevtools = () => {
  if (!configs.isProduction) {
    return;
  }

  const handleCommandKey = (event: KeyboardEvent) => {
    const keyInfo = KeyInfo.from(event);

    if (keyInfo.isFunction("F12")) {
      event.preventDefault();
      return;
    }

    // 윈도우 개발자도구 단축키
    if (keyInfo.isControl && keyInfo.isShift) {
      if (keyInfo.isCharacter("KeyI") || keyInfo.isCharacter("KeyC") || keyInfo.isCharacter("KeyJ")) {
        event.preventDefault();
        return;
      }
    }

    // mac 개발자도구 단축키
    if (keyInfo.isMeta) {
      if (keyInfo.isAlt) {
        if (keyInfo.isCharacter("KeyI") || keyInfo.isCharacter("KeyC") || keyInfo.isCharacter("KeyJ")) {
          event.preventDefault();
          return;
        }
      }

      if (keyInfo.isShift) {
        if (keyInfo.isCharacter("KeyC")) {
          event.preventDefault();
          return;
        }
      }
    }
  };
  window.addEventListener("keydown", handleCommandKey);
};

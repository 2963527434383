import { User } from "App/Store/User";
import { AccountTier } from "constants/AccountTier";
import { AccountType } from "constants/AccountType";
import { USER_INFO } from "constants/storageKey";
import { Cookie } from "lib/cookie";
import { Time } from "lib/time";

export type UserToken = {
  refreshToken: string;
  accessToken: string;
  tokenType: string;
  expiresAt: number;
};

type UserInfo = UserToken & User;

export const UserInfo = {
  load(): null | UserInfo {
    try {
      const {
        type,
        tier,
        userId,
        channelId,
        realId,
        email,
        name,
        avatar,
        provider,
        refreshToken,
        accessToken,
        tokenType,
        expiresAt,
        createdAt
      }: UserInfo = JSON.parse(Cookie.get(USER_INFO)!);
      return {
        type: AccountType.from(type),
        tier: AccountTier.from(tier),
        userId,
        channelId,
        realId,
        email,
        name,
        avatar,
        provider,
        refreshToken,
        accessToken,
        tokenType,
        expiresAt,
        artistId: null,
        createdAt: Time(createdAt)
      };
    } catch {
      return null;
    }
  },
  clear(): void {
    Cookie.remove(USER_INFO);
  },
  save({ type, tier, createdAt, realId, ...userInfo }: UserInfo): void {
    Cookie.set(USER_INFO, { ...userInfo, ...{ createdAt: createdAt.toISOString(), type: type.toString(), tier: tier.toString() } });
  },
  updateToken(token: UserToken): void {
    const { type, tier, realId, ...userInfo } = UserInfo.load()!;
    Cookie.set(USER_INFO, {
      ...userInfo,
      ...{ createdAt: userInfo.createdAt.toISOString(), type: type.toString(), tier: tier.toString() },
      ...token
    });
  }
};

import { combineReducers, createStore } from "lib/store";
import { LocaleInformation } from "services/localeLocaleInformation";
import { Localization } from "./Localization";
import { LocaleActions, LocaleState } from "./Store";

const { useSelector, ...LocalizationStore } = createStore(combineReducers({ Locale: LocaleActions }));

export const initializeLocalization = (information: LocaleInformation): void =>
  LocalizationStore.initialize(() => ({ Locale: LocaleState.of(information) }));

export const currentLanguage = (): string => LocalizationStore.getState().Locale.currentLanguage;
export const fallbackLanguage = (): string => LocalizationStore.getState().Locale.fallbackLanguage;
export const supportLanguageList = (): readonly string[] => LocalizationStore.getState().Locale.supportLanguageList;

const currentLocales = (): Localization => LocalizationStore.getState().Locale.locales[currentLanguage()];
const fallbackLocales = (): Localization => LocalizationStore.getState().Locale.locales[fallbackLanguage()];

export const rawMessage = <Key extends string, Message extends string>({ key }: Locale<{ key: Key; message: Message }>): string =>
  currentLocales()?.raw(key) ?? fallbackLocales()?.raw(key) ?? key;

export const message = <Key extends string, Message extends string>(
  { key, defaultMessage }: Locale<{ key: Key; message: Message }>,
  args?: Record<string, any>
): string =>
  currentLocales()?.message(key, args) ??
  fallbackLocales()?.message(key, args) ??
  Localization.of({ [key]: defaultMessage }).message(key, args)!;

export class Locale<T extends { key: string; message: string } = any> {
  static of<Key extends string, Message extends string>(key: Key, defaultMessage: Message): Locale<{ key: Key; message: Message }> {
    return new Locale(key, defaultMessage);
  }

  private constructor(readonly key: T["key"], readonly defaultMessage: T["message"]) {}
}

export const setCurrentLanguages = (language: string, locale: Localization): void =>
  LocalizationStore.dispatch(LocaleActions.setCurrentLanguage({ language, locale }));

export const setFallbackLanguage = (language: string): void => LocalizationStore.dispatch(LocaleActions.setFallbackLanguage(language));

type MessageProps<Key extends string, Message extends string> = {
  readonly locale: Locale<{ key: Key; message: Message }>;
  readonly args?: Record<string, any>;
};

export const Message = <Key extends string, Message extends string>({
  locale: { key, defaultMessage },
  args
}: MessageProps<Key, Message>): null | JSX.Element =>
  currentLocales()?.node(key, args) ?? fallbackLocales()?.node(key, args) ?? Localization.of({ [key]: defaultMessage }).node(key, args);

export const useLocalization = () =>
  useSelector(
    ({ Locale: { currentLanguage, fallbackLanguage, supportLanguageList } }) =>
      ({
        currentLanguage,
        fallbackLanguage,
        supportLanguageList
      } as const)
  );

export { Localization } from "./Localization";

import { useHistory as useReactHistory } from "react-router";
import { localizePath } from "./localizePath";

type Option = {
  isLocalized?: boolean;
};

export const useHistory = () => {
  const history = useReactHistory();

  return {
    push(path: string, { isLocalized }: Option = {}) {
      const nextPath = isLocalized ? path : localizePath(path);
      history.push(nextPath);
    },
    replace(path: string, { isLocalized }: Option = {}) {
      const nextPath = isLocalized ? path : localizePath(path);
      history.replace(nextPath);
    }
  };
};

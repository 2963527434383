import axios from "axios";
import { GraphQLError } from "graphql";
import { err, ok, Result } from "utils/result";

export interface Query<T> {
  readonly data?: T;
  readonly errors?: readonly GraphQLError[];
}

export type QueryResult<T> = Result<Query<T>, any>;

export interface Mutation<T> {
  readonly data?: T;
  readonly errors?: readonly GraphQLError[];
}

export type MutationResult<T> = Result<Mutation<T>, any>;

export class AppSync {
  static of(url: string, apiKey: string): AppSync {
    return new AppSync(url, apiKey);
  }

  constructor(private readonly url: string, private readonly apiKey: string) {}

  async query<T = any>(query: string, variables: Record<string, any> = {}): Promise<QueryResult<T>> {
    try {
      const response = await axios.post(this.url, { operationName: null, query, variables }, { headers: { "x-api-key": this.apiKey } });
      return ok(response.data);
    } catch (error) {
      return err(error);
    }
  }

  async mutation<T = any>(mutation: string, variables: Record<string, any> = {}): Promise<MutationResult<T>> {
    try {
      const response = await axios.post(
        this.url,
        { operationName: null, query: mutation, variables },
        { headers: { "x-api-key": this.apiKey } }
      );
      return ok(response.data);
    } catch (error) {
      return err(error);
    }
  }
}

import { UserInfo } from "App/Routes/UserTokenManager/UserInfo";
import { configs } from "configs";
import { NOT_USED_LOGGED_OUT } from "constants/Locale";
import { AppSync } from "lib/appsync";
import { HttpClient } from "lib/http-client";
import { message } from "lib/localization";
import { Slack } from "lib/slack";
import { refreshToken } from "services/refreshToken";

export const clients = {
  service: HttpClient.create({
    baseUrl: configs.urls.service,
    getHeader: () => {
      const userInfo = UserInfo.load();
      if (!userInfo) {
        return {};
      }
      const Authorization = `${userInfo.tokenType} ${userInfo.accessToken}`;
      return { Authorization };
    },
    async failover() {
      const userInfo = UserInfo.load();
      if (!userInfo) {
        return;
      }
      const userInfoResult = await refreshToken(userInfo.refreshToken);
      if (userInfoResult.isOk) {
        UserInfo.updateToken(userInfoResult.item);
        return;
      }

      UserInfo.clear();
      window.alert(message(NOT_USED_LOGGED_OUT));
      window.location.reload();
    }
  }),
  language: HttpClient.create({ baseUrl: configs.urls.language }),
  slack: new Slack(configs.urls.slackWebHookUrl),
  slackQna: new Slack(configs.urls.slackWebHookQnaUrl),
  slackLive: new Slack(configs.urls.slackWebHookLiveUrl),
  live: AppSync.of(configs.chatting.url, configs.chatting.apiKey)
};

import { Localization } from "lib/localization";
import { clients } from "utils/clients";

type Item = {
  code: string;
  locales: Localization;
};

export type LocaleItem = Item;

export const loadLocale = async (language: string): Promise<Item> => {
  const locales = await clients.language.get(`${language}/current/index.json?${Date.now()}`);
  return { code: language, locales: Localization.of(locales) };
};

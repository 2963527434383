import { immerable } from "immer";
import { createDuck } from "lib/store";
import { createElement, Fragment } from "react";
import { Localization } from "../Localization";

export type InitialStateProps = {
  readonly currentLanguage?: string;
  readonly fallbackLanguage?: string;
  readonly supportLanguageList?: readonly string[];
  readonly locales?: ReadonlyArray<{ readonly code: string; readonly locales: Localization }>;
};

export class LocaleState {
  static of(
    {
      currentLanguage = "en",
      fallbackLanguage = currentLanguage,
      supportLanguageList = [currentLanguage],
      locales = []
    }: InitialStateProps = {} as InitialStateProps
  ): LocaleState {
    return new LocaleState(
      currentLanguage,
      fallbackLanguage,
      supportLanguageList,
      locales.reduce((record, { code, locales }) => {
        record[code] = locales;
        return record;
      }, {} as Record<string, Localization>)
    );
  }

  readonly [immerable] = true;

  private constructor(
    public currentLanguage: string,
    public fallbackLanguage: string,
    public supportLanguageList: readonly string[],
    public locales: Record<string, Localization>
  ) {
    window.document.documentElement.lang = currentLanguage;
  }

  get message() {
    return (key: string): string =>
      this.locales[this.currentLanguage]?.message(key) ?? this.locales[this.fallbackLanguage]?.message(key) ?? key;
  }

  get node() {
    return (key: string, args?: Record<string, any>): JSX.Element =>
      this.locales[this.currentLanguage]?.node(key, args) ??
      this.locales[this.fallbackLanguage]?.node(key, args) ??
      createElement(Fragment, args, key);
  }
}

const createInitialState = (): LocaleState => {
  throw new Error("Unreachable!");
};

export const LocaleActions = createDuck({
  namespace: "Locale",
  createInitialState,
  reducers: {
    setCurrentLanguage(state, { language, locale }: { language: string; locale: Localization }) {
      state.currentLanguage = language;
      state.locales[language] = locale;
    },
    setFallbackLanguage(state, language: string) {
      state.fallbackLanguage = language;
    }
  }
});

import { negotiateLanguages } from "@fluent/langneg";
import { clients } from "utils/clients";
import { err, ok, Result } from "utils/result";
import { loadLocale, LocaleItem } from "./loadLocale";

type Item = {
  currentLanguage: string;
  fallbackLanguage: string;
  supportLanguageList: string[];
  locales: LocaleItem[];
};

export type LocaleInformation = Item;

export const loadLocaleInformation = async (): Promise<Result<Item, any>> => {
  try {
    const { default_language: fallbackLanguage, list } = await clients.language.get("index.json");
    const supportLanguageList: string[] = list.map(({ language }: any) => language);
    const pathLanguage = getPathLanguage(supportLanguageList);
    const searchLanguage = getSearchLanguage(supportLanguageList);
    const browserLanguage = window.navigator.language;
    const currentLanguage =
      negotiateLanguages([searchLanguage ?? pathLanguage ?? browserLanguage], supportLanguageList)[0] ?? fallbackLanguage;
    const locales = await Promise.all(
      [currentLanguage, currentLanguage !== fallbackLanguage && fallbackLanguage].filter(Boolean).map(loadLocale)
    );
    return ok({ currentLanguage, fallbackLanguage, supportLanguageList, locales });
  } catch (error) {
    return err(error);
  }
};

const getPathLanguage = (supportLanguageList: string[]): null | string => {
  const language = window.location.pathname.slice(1).split("/")[0];
  return supportLanguageList.find(supportLanguage => supportLanguage === language) ?? null;
};

const getSearchLanguage = (supportLanguageList: string[]): null | string => {
  const urlSearchParams = new window.URLSearchParams(window.location.search);
  const language = urlSearchParams.get("lang");
  return supportLanguageList.find(supportLanguage => supportLanguage === language) ?? null;
};

import { supportLanguageList } from "lib/localization";

export const localizePath = (path: string): string => {
  const currentPath = window.location.pathname.slice(1);
  const currentLanguage = supportLanguageList().find(supportLanguage => currentPath.startsWith(supportLanguage));
  if (currentLanguage) {
    return `/${currentLanguage}${path}`;
  }
  return path;
};

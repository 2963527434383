import { LocationState } from "history";
import { NavLink, NavLinkProps } from "react-router-dom";
import { localizePath } from "utils/useRouter";

type Props = NavLinkProps<LocationState>;

export const InternalLink = ({ exact = true, to, children, ...props }: Props) => (
  <NavLink exact={exact} to={localizePath(to as string)} {...props}>
    {children}
  </NavLink>
);

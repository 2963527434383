if (!window.File.prototype.arrayBuffer) {
  // Safari를 위한 Polyfill
  // Safari는 표준 File API의 일부만 구현함
  Object.defineProperty(window.File.prototype, "arrayBuffer", {
    value() {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsArrayBuffer(this);
      });
    }
  });
}

export {};

export class Result<T, E> {
  constructor(readonly variant: Ok<T> | Err<E>) {}

  get isOk() {
    return this.variant.isOk;
  }

  get isErr() {
    return !this.isOk;
  }

  get item() {
    const result = this.variant as Ok<T>;
    return result.item;
  }

  get error() {
    const result = this.variant as Err<E>;
    return result.error;
  }

  okMap<U>(fn: (item: T) => U): Result<U, E> {
    if (this.variant.isOk) {
      return ok(fn(this.variant.item));
    }
    return new Result(this.variant);
  }

  errMap<E2>(fn: (error: E) => E2): Result<T, E2> {
    if (!this.variant.isOk) {
      return err(fn(this.variant.error));
    }
    return new Result(this.variant);
  }

  okThen<U>(fn: (item: T) => Result<U, E>): Result<U, E> {
    if (this.variant.isOk) {
      return fn(this.variant.item);
    }
    return err(this.variant.error);
  }

  errThen<E2>(fn: (error: E) => Result<T, E2>): Result<T, E2> {
    if (!this.variant.isOk) {
      return fn(this.variant.error);
    }
    return ok(this.variant.item);
  }

  unwrap(): T {
    if (this.variant.isOk) {
      return this.variant.item;
    }
    throw new Error("Err can not be unwrapped!");
  }

  unwrapOr(item: T): T {
    if (this.variant.isOk) {
      return this.variant.item;
    }
    return item;
  }

  unwrapOrNull(): null | T {
    if (this.variant.isOk) {
      return this.variant.item;
    }
    return null;
  }

  unwrapOrElse(fn: () => T): T {
    if (this.variant.isOk) {
      return this.variant.item;
    }
    return fn();
  }
}

export class Ok<T> {
  constructor(readonly item: T) {}

  readonly isOk = true;
}

export class Err<E> {
  constructor(readonly error: E) {}

  readonly isOk = false;
}

export const ok = <T>(item: T): Result<T, any> => new Result(new Ok(item));

export const err = <E>(error: E): Result<any, E> => new Result(new Err(error));

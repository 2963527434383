import { Locale } from "lib/localization";

export const SERVICE_BRAND_NAME = Locale.of("service-brand-name", `Artists Card Inc.`);
export const SOME_CONTENT_TO_BE_REMOVE = Locale.of(
  "some-content-to-be-remove",
  `{ $count } contents will be completely deleted and their settlement details will be eliminated. Are you sure you want to delete them?`
);
export const SERVICE_MAIL_ORDER_LICENSE = Locale.of(
  "service-mail-order-license",
  `<a href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=5588800394" target="_blank">제 2018-성남수정-0398호</a>`
);
export const CONTACT_EMAIL = Locale.of(
  "contact-email",
  `<a href="mailto:contact@artistscard.com" target="_blank">contact@artistscard.com</a> / <a href="tel:031-604-7284" target="_blank">031-604-7284</a>`
);
export const ERROR_UNSUPPORTED_AUDIO_FORMAT = Locale.of(
  "error-unsupported-audio-format",
  `Only MP3 extensions with 128kbps or higher bitrates are supported.`
);
export const SERVICE_COMPANY_REGISTRATION = Locale.of("service-company-registration", `558-88-00394`);
export const SERVICE_WEB_HOST = Locale.of("service-web-host", `Amazon Web Service, Asia Paciffic (Seoul)`);
export const CONTENT_WORK_DESCRIPTION = Locale.of(
  "content-work-description",
  `Please, find the artist name of the original song to upload and the song title, and register them.<br />
TIP! You can search them by (original song name) + (artist name).`
);
export const CONTENT_WORK_WARNING = Locale.of(
  "content-work-warning",
  `- If an original song of the track is not registered or is wrongly registered, you will not be able to get adjustment for this content.<br />
- If two or more original songs  in one track, all the original songs shall be registered.<br />
- If there is no original song desired, please tab [Apply for Registering Original Song].
- In the case of your own song, please select [My Own Song].`
);
export const ERROR_IMAGE_SIZE_CONTENT = Locale.of(
  "error-image-size-content",
  `Only images of 480 pixels or more in both width and height can be uploaded.`
);
export const ERROR_IMAGE_SIZE_PROFILE = Locale.of(
  "error-image-size-profile",
  `Only images of 480 pixels or more in both width and height can be uploaded.`
);
export const PLACEHOLDER_INPUT_TEXT_DEFAULT = Locale.of("placeholder-input-text-default", `Enter a search word.`);
export const PRIVACY_POLICY = Locale.of("privacy-policy", `Privacy Policy`);
export const SERVICE_COMPANY_ADDRESS = Locale.of(
  "service-company-address",
  `#523, 54 Changeop-ro, Sujeong-gu, Seongnam-si, Gyeonggi-do, Rep. of Korea`
);
export const CREATE_ACCOUNT = Locale.of("create-account", `Create Account`);
export const PUBLIC = Locale.of("public", `Public`);
export const PUBLIC_STATE = Locale.of("public-state", `Public status`);
export const NOTICE = Locale.of("notice", `FAQ`);
export const FAQ = Locale.of("faq", `FAQ`);
export const ERROR_WHILE_LOADING_NOTICE = Locale.of("error-while-loading-notice", `An error occurs while loading Notice.`);
export const NO_EVENT_NOTICE_CARD = Locale.of("no-event-notice-card", `There are no notice.`);
export const NO_NOTICE = Locale.of("no-notice", `There are no notice.`);
export const RELATION_WORK = Locale.of("relation-work", `Relevant original song`);
export const ADMINISTRATOR = Locale.of("administrator", `Administrator`);
export const ERROR_ONLY_ADMIN = Locale.of("error-only-admin", `This page is only for the administrator.`);
export const BASIC = Locale.of("basic", `Default`);
export const DATE = Locale.of("date", `Date`);
export const DOWNLOAD = Locale.of("download", `Download`);
export const COVERLALA_DOWNLOAD = Locale.of("coverlala-download", `Download Artists'Card`);
export const CLOSE = Locale.of("close", `Close`);
export const REPLY = Locale.of("reply", `Reply`);
export const CLOSE_REPLIES = Locale.of("close-replies", `Fold Reply`);
export const ERROR_WHILE_REMOVING_REPLY = Locale.of("error-while-removing-reply", `An error occurs while deleting the reply.`);
export const CONFIRM_REMOVE_REPLY = Locale.of("confirm-remove-reply", `Are you sure you want to delete the reply?`);
export const ERROR_WHILE_ADDING_REPLY = Locale.of("error-while-adding-reply", `An error occurs while creating the reply.`);
export const CEO = Locale.of("ceo", `CEO`);
export const COMMENTS = Locale.of("comments", `Comment`);
export const GO_TO_COMMENTS = Locale.of("go-to-comments", `View Comment`);
export const COMMENT_COUNT = Locale.of("comment-count", `Comment`);
export const ERROR_WHILE_LOADING_COMMENTS = Locale.of("error-while-loading-comments", `An error occurs while loading the comment`);
export const CONFIRM_REMOVE_COMMENT = Locale.of("confirm-remove-comment", `Are you sure you want to delete the comment?`);
export const LOAD_MORE = Locale.of("load-more", `View More`);
export const ERROR_WHILE_VALIDATING_DATA = Locale.of("error-while-validating-data", `Failed to verify the data.`);
export const AGREE = Locale.of("agree", `Agree`);
export const REGISTER = Locale.of("register", `Register`);
export const NO_CONTENT = Locale.of("no-content", `No contents registered`);
export const LIVE_STREAMING = Locale.of("live-streaming", `Live Streaming`);
export const SIGN_OUT = Locale.of("sign-out", `Log Out`);
export const SIGN_IN = Locale.of("sign-in", `Log in`);
export const GO_TO_SIGN_IN = Locale.of("go-to-sign-in", `Back to Login`);
export const ERROR_REQUIRED_SIGN_IN = Locale.of("error-required-sign-in", `Login is required.`);
export const ADD_CONTENT = Locale.of("add-content", `Upload Contents`);
export const GO_TO_MAIN = Locale.of("go-to-main", `Back to Main`);
export const ALL_CONTENT_TO_BE_PUBLIC = Locale.of("all-content-to-be-public", `Are you sure you want to make all 'Public'?`);
export const ALL_CONTENT_TO_BE_PRIVATE = Locale.of("all-content-to-be-private", `Are you sure you want to make all 'Private'?`);
export const ALL_CONTENT_TO_BE_CHECKING = Locale.of("all-content-to-be-checking", `Are you sure you want to make all 'Reviewing'?`);
export const MOOD = Locale.of("mood", `Mood`);
export const MOOD_SEARCH_HEADING = Locale.of("mood-search-heading", `Search mood`);
export const CONTACT = Locale.of("contact", `Contact`);
export const UPDATE = Locale.of("update", `Change settings`);
export const CANCEL_MODIFICATION = Locale.of("cancel-modification", `Cancel Changes`);
export const NOTICE_CATEGORY = Locale.of("notice-category", `Category`);
export const PRIVATE = Locale.of("private", `Private`);
export const PASSWORD = Locale.of("password", `Password`);
export const CHANGE_PASSWORD = Locale.of("change-password", `Change Password`);
export const PASSWORD_CONFIRM = Locale.of("password-confirm", `Confirm Password`);
export const FORGOT_PASSWORD = Locale.of("forgot-password", `Forgot your password?`);
export const SUCCESS_UPDATE_PASSWORD = Locale.of("success-update-password", `The password is changed.`);
export const ERROR_PASSWORD_LENGTH_GREATER = Locale.of("error-password-length-greater", `Enter below 32 characters for a password.`);
export const ERROR_PASSWORD_LENGTH_LESSER = Locale.of("error-password-length-lesser", `Enter at least 8 characters for a password.`);
export const ERROR_PASSWORD_INCLUDE_NO_NUMERIC = Locale.of(
  "error-password-include-no-numeric",
  `A password shall contain one or more number.`
);
export const ERROR_PASSWORD_INCLUDE_NO_ALPHABET = Locale.of(
  "error-password-include-no-alphabet",
  `A password shall contain one or more alphabet.`
);
export const ERROR_PASSWORD_INCLUDE_NO_SPECIAL_CHARACTER = Locale.of(
  "error-password-include-no-special-character",
  `A password shall contain one or more special characters.`
);
export const NO_PASSWORD_CONFIRM = Locale.of("no-password-confirm", `Enter the password, again.`);
export const NO_PASSWORD = Locale.of("no-password", `Enter the password.`);
export const COMPANY_REGISTRATION = Locale.of("company-registration", `Business registration number`);
export const ERROR_INVALIDATED_NAME = Locale.of("error-invalidated-name", `This name cannot be used.`);
export const ERROR_UNEXCEPTABLE_EMAIL = Locale.of("error-unexceptable-email", `This e-mail cannot be used.`);
export const REMOVE = Locale.of("remove", `Delete`);
export const REMOVED_COMMENT = Locale.of("removed-comment", `Deleted comment`);
export const REMOVED_REPLY = Locale.of("removed-reply", `Deleted reply`);
export const CONTENT_CREATE_HEADING_DETAIL_INFORMATION = Locale.of("content-create-heading-detail-information", `Details`);
export const NEXT_PASSWORD = Locale.of("next-password", `New Password`);
export const NEXT_PASSWORD_CONFIRM = Locale.of("next-password-confirm", `Confirm New Password`);
export const MISMATCHED_NEXT_PASSWORD = Locale.of("mismatched-next-password", `The new password does not match.`);
export const ERROR_NEXT_PASSWORD_LENGTH_GREATER = Locale.of(
  "error-next-password-length-greater",
  `Enter below 32 characters for a password.`
);
export const ERROR_NEXT_PASSWORD_LENGTH_LESSER = Locale.of(
  "error-next-password-length-lesser",
  `Enter at least 8 characters for a password.`
);
export const ERROR_NEXT_PASSWORD_INCLUDE_NO_NUMERIC = Locale.of(
  "error-next-password-include-no-numeric",
  `A new password shall contain one or more numbers.`
);
export const ERROR_NEXT_PASSWORD_INCLUDE_NO_ALPHABET = Locale.of(
  "error-next-password-include-no-alphabet",
  `A new password shall contain one or more alphabetical characters.`
);
export const ERROR_NEXT_PASSWORD_INCLUDE_NO_SPECIAL_CHARACTER = Locale.of(
  "error-next-password-include-no-special-character",
  `A new password shall contain one or more special characters.`
);
export const NO_NEXT_PASSWORD_CONFIRM = Locale.of("no-next-password-confirm", `Enter the password, again.`);
export const NO_NEXT_PASSWORD = Locale.of("no-next-password", `Enter a new password.`);
export const ERROR_WHILE_LOADING_NEXT_REPLIES = Locale.of(
  "error-while-loading-next-replies",
  `An error occurs while loading a new comment.`
);
export const CREATE = Locale.of("create", `Create`);
export const ERROR_API_REQUEST_OR_INTERNET_CONNECTION = Locale.of(
  "error-api-request-or-internet-connection",
  `The server or Internet connection status fails.`
);
export const OPTIONAL = Locale.of("optional", `Optional`);
export const PLACEHOLDER_NO_AUDIO_FILE = Locale.of("placeholder-no-audio-file", `No files selected`);
export const DESCRIPTION = Locale.of("description", `Description`);
export const PLACEHOLDER_CONTENT_DESCRIPTION = Locale.of(
  "placeholder-content-description",
  `Create a content introduction by using keywords. Then, viewers can easily find you content by searching words.\nTry to briefly explain the content description and place the keywords at the beginning of the description.`
);
export const SETTING = Locale.of("setting", `Settings`);
export const SETTLEMENT = Locale.of("settlement", `Settle Profits`);
export const CONFIRM_TO_RETRY = Locale.of("confirm-to-retry", `An error occurs while modifying it. Are you sure you want to try it again?`);
export const ERROR_WHILE_MODIFICATING = Locale.of("error-while-modificating", `Failed to modify it.`);
export const CHECKING = Locale.of("checking", `Reviewing`);
export const THUMBNAIL = Locale.of("thumbnail", `Thumbnail`);
export const NO_COMMENTS = Locale.of("no-comments", `No comments`);
export const ERROR_WHILE_LOADING_ARTIST_INFORMATION = Locale.of(
  "error-while-loading-artist-information",
  `Failed to load the artist information.`
);
export const ERROR_DISAGREE_TERM = Locale.of("error-disagree-term", `Please agree to the Terms of Use.`);
export const UPLOAD = Locale.of("upload", `Upload`);
export const AUDIO_DROPZONE = Locale.of("audio-dropzone", `Drag and drop an <span>MP3</span> file.`);
export const AUDIO_UPLOAD = Locale.of("audio-upload", `Upload Audio`);
export const WEB_HOST = Locale.of("web-host", `Web Host`);
export const YOUTUBE_LINK = Locale.of("youtube-link", `YouTube Link`);
export const SUCCESS_UPDATE_AUDIO = Locale.of("success-update-audio", `The track is successfully changed.`);
export const ERROR_WHILE_INVALIDATING_AUDIO_CACHE = Locale.of("error-while-invalidating-audio-cache", `Failed to change the track.`);
export const CONTENT_CREATE_HEADING_UPLOAD_AUDIO = Locale.of("content-create-heading-upload-audio", `Upload Contents`);
export const ERROR_WHILE_REQUESTING_TO_UPLOADING_AUDIO = Locale.of(
  "error-while-requesting-to-uploading-audio",
  `Failed to request the upload of the track.`
);
export const ERROR_WHILE_UPLOADING_AUDIO = Locale.of("error-while-uploading-audio", `Failed to upload the track.`);
export const EMAIL = Locale.of("email", `E-mail`);
export const ERROR_CHECK_EMAIL_OR_PASSWORD = Locale.of("error-check-email-or-password", `Wrong email or password!`);
export const ERROR_EMAIL_OR_PASSWORD = Locale.of("error-email-or-password", `Wrong email or password!`);
export const SEND_EMAIL = Locale.of("send-email", `Send out e-mail`);
export const INVALIDATED_EMAIL_FORMAT = Locale.of("invalidated-email-format", `The email format is invalid.`);
export const NO_EMAIL = Locale.of("no-email", `Enter the email.`);
export const ERROR_ALREADY_EXISTS_EMAIL = Locale.of("error-already-exists-email", `This email already exists.`);
export const ERROR_ALREADY_EXISTS_CHANNEL_NAME = Locale.of("error-already-exists-channel-name", `This channel name already exists.`);
export const IMAGE_CROP_HEADING = Locale.of("image-crop-heading", `Change Image`);
export const ERROR_WHILE_CREATING_IMAGE = Locale.of("error-while-creating-image", `Failed to create an image.`);
export const SELECT_IMAGE = Locale.of("select-image", `Select Image`);
export const ERROR_WHILE_INVALIDATING_IMAGE_CACHE = Locale.of("error-while-invalidating-image-cache", `Failed to update the image cache.`);
export const ERROR_WHILE_REQUESTING_TO_UPLOADING_IMAGE = Locale.of(
  "error-while-requesting-to-uploading-image",
  `Failed to request the upload of the image file.`
);
export const ERROR_WHILE_UPLOADING_IMAGE = Locale.of("error-while-uploading-image", `Failed to upload the image file.`);
export const NO_SUPPORT_IMAGE_EDITING_FOR_MOBILE = Locale.of(
  "no-support-image-editing-for-mobile",
  `Image editing function is not supported on mobile studio.`
);
export const SUCCESS_UPDATE_IMAGE = Locale.of("success-update-image", `The image is changed.`);
export const SUCCESS_ADD_IMAGE = Locale.of("success-add-image", `The image is added.`);
export const EVENT_NOTICE_CARD_HEADING = Locale.of("event-notice-card-heading", `Event notice`);
export const ERROR_WHILE_LOADING_EVENT_NOTICE = Locale.of(
  "error-while-loading-event-notice",
  `An error occurred while loading the event notice.`
);
export const TERMS_OF_SERVICE = Locale.of("terms-of-service", `Terms of Use`);
export const AGREE_TERMS = Locale.of("agree-terms", `I have read and agree to the <a href=$url target="_blank">Terms of Use</a>.`);
export const CONFIRM_PRIVACY_POLICY = Locale.of(
  "confirm-privacy-policy",
  `I have checked the <a href=$url target="_blank">Privacy Policy</a>.`
);
export const ISSUE_TEMPORARY_PASSWORD = Locale.of("issue-temporary-password", `Issue Temporary Password`);
export const TYPE_EMAIL_FOR_ISSUING_TEMPORARY_PASSWORD = Locale.of(
  "type-email-for-issuing-temporary-password",
  `Enter an email to receive a temporary password.`
);
export const CONFIRM_RESET_PASSWORD = Locale.of(
  "confirm-reset-password",
  `A temporary password is sent to the email. It is valid for 7 days. Within the period, change the password mandatorily.`
);
export const INPUT = Locale.of("input", `Input`);
export const CREATED_AT = Locale.of("created-at", `Registration Date`);
export const AUTHOR = Locale.of("author", `Creator`);
export const WORK = Locale.of("work", `Original song`);
export const WORK_SEARCH_HEADING = Locale.of("work-search-heading", `Search Original Song`);
export const PLACEHOLDER_INPUT_WORK = Locale.of("placeholder-input-work", `Enter the original song.`);
export const NO_COVER_WORK = Locale.of("no-cover-work", `No original songs`);
export const GENRE = Locale.of("genre", `Genre`);
export const GENRE_AND_MOOD = Locale.of("genre-and-mood", `Genre / Mood`);
export const NO_COVER_TAGS = Locale.of("no-cover-tags", `Please enter genres/moods.`);
export const GENRE_SEARCH_HEADING = Locale.of("genre-search-heading", `Search genre`);
export const PLACEHOLDER_INPUT_GENRE_AND_MOOD = Locale.of("placeholder-input-genre-and-mood", `Please select a genre / mood.`);
export const PLACEHOLDER_INPUT_MOOD = Locale.of("placeholder-input-mood", `Select a mood.`);
export const PLAY_COUNT = Locale.of("play-count", `Plays`);
export const PLAYLIST = Locale.of("playlist", `Playlists`);
export const SAVE = Locale.of("save", `Save`);
export const SEND = Locale.of("send", `Send`);
export const CEO_NAME = Locale.of("ceo-name", `Micky Jung`);
export const TITLE = Locale.of("title", `Title`);
export const ERROR_NO_TITLE = Locale.of("error-no-title", `Enter the title.`);
export const UNEXISTED_EMAIL = Locale.of("unexisted-email", `This email does not exist.`);
export const LIKE = Locale.of("like", `Like`);
export const LIKE_COUNT = Locale.of("like-count", `Likes`);
export const COMPANY_ADDRESS = Locale.of("company-address", `Address`);
export const COMING_SOON = Locale.of("coming-soon", `Coming soon!`);
export const ERROR_IMAGE_NO_SUPPORT = Locale.of("error-image-no-support", `This file format is not supported.`);
export const CHANNEL = Locale.of("channel", `Channel`);
export const CHANNEL_DASHBOARD = Locale.of("channel-dashboard", `Channel Dashboard`);
export const CHANNEL_CREATED_AT = Locale.of("channel-created-at", `Channel Creation Date`);
export const CONTENT_LIST_HEADING = Locale.of("content-list-heading", `Content List`);
export const CHANNEL_NAME = Locale.of("channel-name", `Channel Name`);
export const NO_CHANNEL_NAME = Locale.of("no-channel-name", `Enter the channel name.`);
export const CONTENT_CREATE_HEADING_LAST_CONFIRM = Locale.of("content-create-heading-last-confirm", `Final Check and Registration`);
export const CANCEL = Locale.of("cancel", `Cancel`);
export const TOOLTIP_CONTENT_TITLE = Locale.of("tooltip-content-title", `Enter the singer and the cover song.`);
export const COVER_IMAGE = Locale.of("cover-image", `Thumbnail`);
export const ERROR_NO_COVER_IMAGE = Locale.of("error-no-cover-image", `Please, register a Thumbnail.`);
export const COVER_WORK = Locale.of("cover-work", `Original Song`);
export const PLACEHOLDER_CONTENT_WORK = Locale.of("placeholder-content-work", `Please, select a original song.`);
export const TOOLTIP_CONTENT_GENRE_AND_MOOD = Locale.of(
  "tooltip-content-genre-and-mood",
  `Try to apply a mood tag that you think is appropriate for original song.
You can listen to music with the mood or situation that users want to listen to by automatic selection.`
);
export const TOOLTIP_CONTENT_MOOD = Locale.of(
  "tooltip-content-mood",
  `Try to apply a mood tag that you think is appropriate for original song.
You can listen to music with the mood or situation that users want to listen to by automatic selection.`
);
export const DOCUMENT_TITLE = Locale.of("document-title", `Artists'Card Studio`);
export const TOOLTIP_CONTENT_WORK = Locale.of(
  "tooltip-content-work",
  `Please, select one or more original songs.
- It is easily seen in the service. 
- If an original song is not linked, the settlement is unavailable.`
);
export const CONTENT = Locale.of("content", `Contents`);
export const CONTENTS = Locale.of("contents", `Contents`);
export const CONTENT_COMMENT_HEADING = Locale.of("content-comment-heading", `Content Comment`);
export const CONTENT_CREATE = Locale.of("content-create", `Register Content`);
export const SUCCESS_CONTENT_CREATE = Locale.of(
  "success-content-create",
  `The content is successfully registered. Do you want to check it?`
);
export const ERROR_CONTENT_CREATE_RETRY = Locale.of(
  "error-content-create-retry",
  `Failed to register the content. Are you sure you want to try it again?`
);
export const CONTENT_CREATING = Locale.of("content-creating", `Content Registering`);
export const CONTENT_CREATE_HEADING = Locale.of("content-create-heading", `Create Content`);
export const TOOLTIP_CONTENT_DESCRIPTION = Locale.of("tooltip-content-description", `Try to create the content introduction.`);
export const PLACEHOLDER_NO_DESCRIPTION = Locale.of("placeholder-no-description", `No content introduction`);
export const CONTENT_EDIT_HEADING = Locale.of("content-edit-heading", `Modify Content`);
export const SUCCESS_UPDATE_CONTENT = Locale.of("success-update-content", `The content information is modified.`);
export const PLACEHOLDER_CONTENT_TITLE = Locale.of(
  "placeholder-content-title",
  `ex) BTS-ON Piano ver. (by. Brown)
`
);
export const TOOLTIP_CONTENT_COVER_IMAGE = Locale.of(
  "tooltip-content-cover-image",
  `You can upload a photo to be used as a preview image.
Larger photos with good quality tend to attract users' attention.
Custom thumbnail recommendations include:
- Resolution: 1280x720
- Image format: JPG, JPEG, PNG
- Up to 2 MB
- 16:9 (min. 480 px)`
);
export const TOOLTIP_PROFILE_IMAGE = Locale.of(
  "tooltip-profile-image",
  `Try to upload a photo that introduces yourself.
Profile image recommendations include:
- Resolution: 640x640
- Image format: JPG, JPEG, PNG
- Up to 2 MB
- 1:1 (min. 480 px)`
);
export const ERROR_WHILE_LOADING_CONTENT = Locale.of("error-while-loading-content", `An error occurs while loading the content.`);
export const PLACEHOLDER_CONTENT_YOUTUBE = Locale.of("placeholder-content-youtube", `Enter the YouTube URL.`);
export const PLACEHOLDER_NO_YOUTUBE_LINK = Locale.of("placeholder-no-youtube-link", `There is no Youtube link.`);
export const MAIL_ORDER_LICENSE = Locale.of("mail-order-license", `Mail-order registration number`);
export const TRACK = Locale.of("track", `Track`);
export const CHANGE_AUDIO = Locale.of("change-audio", `Change File`);
export const SELECT_AUDIO_FILE = Locale.of("select-audio-file", `Select File`);
export const WARNING_ESCAPING_PAGE = Locale.of("warning-escaping-page", `Are you sure you want to exit the page? The change is not saved.`);
export const EDIT = Locale.of("edit", `Edit`);
export const PROFILE = Locale.of("profile", `Profile`);
export const PLAYLISTS = Locale.of("playlists", `Playlist`);
export const REQUIRED = Locale.of("required", `Required`);
export const TOOLTIP_CONTENT_YOUTUBE = Locale.of(
  "tooltip-content-youtube",
  `If there is a YouTube video of this content, try to register the URL.
Users can easily watch the YouTube video.
* Enter a link for a video rather than the channel URL.
e.g. https://www.youtube.com/<b>watch?v=2fnStlycFW8</b>`
);
export const CURRENT_PASSWORD = Locale.of("current-password", `Current Password`);
export const NO_CURRENT_PASSWORD = Locale.of("no-current-password", `Enter the current password.`);
export const MISMATCHED_PASSWORD = Locale.of("mismatched-password", `The password does not match.`);
export const CURRENT_AUDIO_FILE = Locale.of("current-audio-file", `Current File`);
export const HOME = Locale.of("home", `Home`);
export const CONFIRM = Locale.of("confirm", `Confirm`);
export const BRAND = Locale.of("brand", `Name of Company`);
export const SIGN_UP = Locale.of("sign-up", `Sign up`);
export const SUCCESS_SIGN_UP = Locale.of("success-sign-up", `Your sign-up is completed.`);
export const CATCH_PHRASE_0 = Locale.of("catch-phrase-0", `Sing the music you like`);
export const CATCH_PHRASE_1 = Locale.of("catch-phrase-1", `Try to create profits!`);
export const CANNOT_UPDATE_CHANNEL_NAME = Locale.of(
  "cannot-update-channel-name",
  `After the initial setting, a channel name cannot be changed. Be careful when setting a channel name.`
);
export const PLACEHOLDER_REPLY = Locale.of("placeholder-reply", `Comments not related to the title and malicious comments may be deleted.`);
export const LEGAL_NOTICE = Locale.of("legal-notice", `Legal Notice`);
export const ERROR_SELECT_THE_GENRES_LIMIT = Locale.of("error-select-the-genres-limit", `Up to 3 genres can be selected.`);
export const ERROR_SELECT_THE_MOODS_LIMIT = Locale.of("error-select-the-moods-limit", `Up to 5 moods can be selected.`);
export const ERROR_SELECT_THE_WORKLIST_LIMIT = Locale.of(
  "error-select-the-worklist-limit",
  `One or more original songs shall be selected.`
);
export const PREVIOUS = Locale.of("previous", `Previous`);
export const NEXT = Locale.of("next", `Next`);
export const GO_BACK = Locale.of("go-back", `Back`);
export const TOOLTIP_CONTENT_TRACK = Locale.of("tooltip-content-track", `tooltip-content-track`);
export const TOOLTIP_GENRE = Locale.of(
  "tooltip-genre",
  `Try to apply a genre that you think is appropriate for original song.
- Up to 3 genres can be selected. 
- You can listen to music with the genre that users want to listen to by automatic selection.`
);
export const TOOLTIP_MOOD = Locale.of(
  "tooltip-mood",
  `Try to apply a mood tag that you think is appropriate for original song.
- Up to 5 moods can be selected.
- You can listen to music with the mood or situation that users want to listen to by automatic selection.`
);
export const SELECT_LANGUAGE = Locale.of("select-language", `Language`);
export const LANGUAGE_ALLOWLIST = Locale.of("language-allowlist", `ko,en,ja`);
export const LANGUAGE_KO = Locale.of("language-ko", `한국어`);
export const LANGUAGE_EN = Locale.of("language-en", `English`);
export const LANGUAGE_JA = Locale.of("language-ja", `日本語`);
export const LANGUAGE_ZH_CN = Locale.of("language-zh-cn", `中文 (简体)`);
export const LANGUAGE_ZH = Locale.of("language-zh", `中文 (繁體)`);
export const UPDATE_DESCRIPTION = Locale.of("update-description", `Change Description`);
export const UPDATE_YOUTUBE_URL = Locale.of("update-youtube-url", `Change YouTube URL`);
export const UPDATE_TITLE = Locale.of("update-title", `Change Title`);
export const ADD_GENRE_AND_MOOD = Locale.of("add-genre-and-mood", `Add genres / moods`);
export const REMOVE_GENRE_AND_MOOD = Locale.of("remove-genre-and-mood", `Delete genre / moods`);
export const ADD_MOOD = Locale.of("add-mood", `Add moods`);
export const REMOVE_MOOD = Locale.of("remove-mood", `Delete moods`);
export const ADD_WORK = Locale.of("add-work", `Add Original Song`);
export const REMOVE_WORK = Locale.of("remove-work", `Delete Original Song`);
export const ADD_WORK_REQUEST = Locale.of("add-work-request", `Add application for original song registration`);
export const REMOVE_WORK_REQUEST = Locale.of("remove-work-request", `Delete application for original song registration`);
export const MORE_INFORMATION = Locale.of("more-information", `Details`);
export const CONTENT_UPDATE = Locale.of("content-update", `Update Content`);
export const CONTENT_UPDATING = Locale.of("content-updating", `Content Updating...`);
export const CONTENT_UPDATE_ERROR = Locale.of("content-update-error", `Failed to update the content.`);
export const CONTENT_UPDATED = Locale.of("content-updated", `The content update is completed.`);
export const ERROR = Locale.of("error", `Error`);
export const IDLE = Locale.of("idle", `Waiting...`);
export const UPDATING = Locale.of("updating", `Updating...`);
export const UPDATED = Locale.of("updated", `Changed`);
export const TAB_ALL = Locale.of("tab-all", `All`);
export const TAB_HISTORY = Locale.of("tab-history", `History`);
export const NOT_SUPPORT_MOBILE_UPLOAD = Locale.of(
  "not-support-mobile-upload",
  `Content upload function is not supported on mobile studio. Access via studio.artistscard.com on a PC.`
);
export const ERROR_WHILE_LOADING_DONATION = Locale.of("error-while-loading-donation", `An error occurs while loading the donation list.`);
export const USERNAME = Locale.of("username", `Nickname`);
export const GIFT_COUNT = Locale.of("gift-count", `Gifts`);
export const DONATION_KIND_GIFT = Locale.of("donation-kind-gift", `{ gift }`);
export const DONATION_KIND_FANMAIL = Locale.of("donation-kind-fanmail", `{ fanmail }`);
export const DONATION_HISTORY = Locale.of("donation-history", `Donation History`);
export const ALL_TIME_GIFT_COUNT = Locale.of("all-time-gift-count", `Current Gift`);
export const COUNT_IN_NATIVE = Locale.of("count-in-native", `{ $count } pcs`);
export const EXCHANGE = Locale.of("exchange", `Payout`);
export const FANMAIL = Locale.of("fanmail", `FAN MAIL`);
export const DONATION_ITEM = Locale.of("donation-item", `{ gift }`);
export const MINIMUM_GIFT_COUNT_FOR_EXCHANGE = Locale.of("minimum-gift-count-for-exchange", `You can payout gifts from 1,000 gifts.`);
export const EXCHANGE_NOT_READY = Locale.of(
  "exchange-not-ready",
  `Currently, we are preparing fuction to apply for gift Payout.
It is expected to be completed in mid-January,
and we hope for your understanding.
For more information, please contact via contact@artistscard.com.
Thank you.`
);

export const NO_DONATION_GIFT = Locale.of("no-donation-gift", `There is no history of receiving { gift }.`);
export const NO_DONATION_FANMAIL = Locale.of("no-donation-fanmail", `No fanmail history`);
export const TAB_SIGN_IN = Locale.of("tab-sign-in", `Log in`);
export const TAB_SIGN_UP = Locale.of("tab-sign-up", `Sign up`);
export const TAB_FORGOT_PASSWORD = Locale.of("tab-forgot-password", `Issue Temporary Password`);
export const EXCHANGEABLE_GIFT = Locale.of("exchangeable-gift", `Gift available for payout`);
export const EXCHANGED_GIFT = Locale.of("exchanged-gift", `Gift after Payout`);
export const ERROR_NO_STARTS_AT = Locale.of("error-no-starts-at", `Set the start date.`);
export const ERROR_NO_ENDS_AT = Locale.of("error-no-ends-at", `Set the end date.`);
export const COMMUNITY = Locale.of("community", `Community`);
export const FANMAIL_COUNT = Locale.of("fanmail-count", `{ fanmail }`);
export const SELECTED_ITEM_COUNT = Locale.of("selected-item-count", `Select { $count } pcs`);
export const NO_FANMAIL = Locale.of("no-fanmail", `No { fanmail }`);
export const ARTIST = Locale.of("artist", `Artists`);
export const REMOVED_FANMAIL = Locale.of("removed-fanmail", `This { fanmail } is deleted.`);
export const PLACEHOLDER_INPUT_INSTRUMENT = Locale.of("placeholder-input-instrument", `Please, enter the instrument name.`);
export const DONATION_KIND = Locale.of("donation-kind", `How to Donate`);
export const DONATION_TARGET = Locale.of("donation-target", `Donation Target`);
export const INSTRUMENT = Locale.of("instrument", `Instrument`);
export const INSTRUMENT_SEARCH_HEADING = Locale.of("instrument-search-heading", `Search Instrument`);
export const ERROR_SELECT_THE_INSTRUMENT_LIMIT = Locale.of("error-select-the-instrument-limit", `Up to 3 instruments can be selected.`);
export const NO_DONATION = Locale.of("no-donation", `No donation history`);
export const MESSAGE = Locale.of("message", `Description`);
export const DONATION_FANMAIL_HISTORY = Locale.of("donation-fanmail-history", `{ fanmail }`);
export const DONATION_GIFT_HISTORY = Locale.of("donation-gift-history", `{ gift }`);
export const DONATION = Locale.of("donation", `Item Category`);
export const TOOLTIP_INSTRUMENT = Locale.of(
  "tooltip-instrument",
  `Try to apply the instrument frequently played in the channel.
- Up to 3 instruments can be selected. `
);
export const INSTRUMENT_INFORMATION = Locale.of("instrument-information", `Instrument`);
export const ERROR_WHILE_LOADING_INSTRUMENT = Locale.of(
  "error-while-loading-instrument",
  `An error occurs while loading the instrument information.`
);
export const PLACEHOLDER_CONTENT_INSTRUMENT = Locale.of("placeholder-content-instrument", `Select an instrument.`);
export const GIFT = Locale.of("gift", `GIFT`);
export const CHANNEL_INFORMATION = Locale.of("channel-information", `Channel Info.`);
export const REPORT = Locale.of("report", `Report`);
export const REASONS_FOR_REPORTING = Locale.of("reasons-for-reporting", `Reason for report`);
export const PLACEHOLDER_CONTENT_GENRE = Locale.of("placeholder-content-genre", `Please select a genre.`);
export const REPORTING_IS_NOT_EXPRESSING_OPPOSITION = Locale.of(
  "reporting-is-not-expressing-opposition",
  `Report is not a function of expressing your objections.`
);
export const FALSE_REPORTING_RESTRICT_ACCOUNT = Locale.of(
  "false-reporting-restrict-account",
  `In the case of false reports, the reporter account may be restricted.`
);
export const ERROR_WHILE_LOADING_CHANNEL_INFORMATION = Locale.of(
  "error-while-loading-channel-information",
  `An error occurs while loading the channel information.`
);
export const KIND = Locale.of("kind", `Type`);
export const ERROR_WHILE_UPDATING_INSTRUMENT = Locale.of(
  "error-while-updating-instrument",
  `An error occurs while updating the instrument information.`
);
export const ERROR_WHILE_UPDATING_GENRE = Locale.of("error-while-updating-genre", `An error occurs while updating the genre information.`);
export const GENRE_INFORMATION = Locale.of("genre-information", `Genre`);
export const FAQ_CATEGORY_ALL = Locale.of("faq-category-all", `All`);
export const FAQ_CATEGORY_NORMAL = Locale.of("faq-category-normal", `General`);
export const FAQ_CATEGORY_SERVICE = Locale.of("faq-category-service", `Service`);
export const FAQ_CATEGORY_FUNCTION = Locale.of("faq-category-function", `Function`);
export const FAQ_CATEGORY_EXCHANGE = Locale.of("faq-category-exchange", `Payout`);
export const FAQ_ALLOWLIST = Locale.of("faq-allowlist", `1,2,3,4,5,6,7,8,9,10,11,12,13,15`);
export const FAQ_1_CATEGORY = Locale.of("faq-1-category", `{ faq-category-normal }`);
export const FAQ_1_TITLE = Locale.of("faq-1-title", `Guide to Content Registration`);
export const FAQ_1_TEXT = Locale.of(
  "faq-1-text",
  `Welcome to Artists'Card Studio!<br />
Convenient for everyone with Artists'Card and Artists'Card Studio, you can be a artist.<br />
Try to cover the songs of your favorite singers and create profits!<br />
<br />
<h3>Guide to Content Registration</h3><br />
<b>Channel name</b><br />
This is the name of your channel as it will appear on Artists'Card.<br />
Note) Unavailable to change your channel name.<br />
Channel names that contain some inappropriate words or are already in use cannot be set.<br />
<br />
<b>Audio</b><br />
Only MP3 files (128 kbps or higher) are supported.<br />
<br />
<b>Title</b><br />
Enter the name of artist who created the content and the song name.<br />
e.g. Sister version of ""Brother by Norazo"" that made all people in the studio cry (cover by Je Se-Hee)<br />
<br />
<b>Connection to the original song</b><br />
You can link to the original song page to make it easier to be exposed to the service.<br />
<br />
<b>Content description</b><br />
Using keywords to write a description makes it easier for viewers to find your content through search.<br />
Try to briefly explain the content description and place the keywords at the beginning of the description.<br />
(Hashtags can be applied. Hashtags in the description can be used as search keywords.)<br />
<br />
<b>Thumbnail</b><br />
You can upload a photo that is used as a preview image. Larger photos with good quality tend to attract users' attention.<br />
Custom thumbnail recommendations include:<br />
- Resolution: 1280x720<br />
- Image format: JPG, JPEG, PNG<br />
- Up to 2 MB<br />
- 16:9 (min. 480 px)<br />
Custom thumbnails shall follow the guideline of Artists'Card.<br />
Violations may result in removal of the thumbnail and a strike on your account. Thumbnails including one of the following items may be rejected: <br />
- Nudity or sexually provocative contents<br />
- Hate speech<br />
- violence<br />
- Harmful or dangerous contents<br />
<br />
<b>Application of genre/mood</b><br />
Try to apply a genre or mood tag that you think is appropriate for your cover original song.<br />
You can listen to music with the genre, mood, or situation that users want to listen to by automatic selection.<br />
<br />
<b>YouTube link URL</b><br />
If there is a YouTube video of the cover song, try to register the URL. Users can easily watch the YouTube video.`
);
export const FAQ_2_CATEGORY = Locale.of("faq-2-category", `{ faq-category-function }`);
export const FAQ_2_TITLE = Locale.of("faq-2-title", `Can I upload content on mobile studio?`);
export const FAQ_2_TEXT = Locale.of(
  "faq-2-text",
  `Content upload is not supported on mobile studio.<br />
Even if it is cumbersome, please upload it using PC version studio.<br />
<a href=""https://studio.artistscard.com/"">https://studio.artistscard.com/</a><br />
<br />`
);
export const FAQ_3_CATEGORY = Locale.of("faq-3-category", `{ faq-category-normal }`);
export const FAQ_3_TITLE = Locale.of("faq-3-title", `When is the live streaming function provided?`);
export const FAQ_3_TEXT = Locale.of(
  "faq-3-text",
  `We plan to launch the live streaming function by the first quarter of 2021 (January to March).`
);
export const FAQ_4_CATEGORY = Locale.of("faq-4-category", `{ faq-category-normal }`);
export const FAQ_4_TITLE = Locale.of("faq-4-title", `I already have an Artists'Card channel. (Channel verification)`);
export const FAQ_4_TEXT = Locale.of(
  "faq-4-text",
  `If you are a creator who already has a channel in Artists'Card, you can use Artists'Card Studio through identify verification without needing to newly join the studio.<br />
<br />
1. How to Verify Your Identity<br />
In your channel in the Artists'Card app {'>'} tap the '?' button (next to the channel name) {'>'} and apply for identity verification.<br />
<br />
2. Other Information<br />
- Your accumulated proceeds from gifts can be also settled through identity verification.<br />
- To apply for identity verification, you shall submit an identity document, as well as your social media channels, etc. (Instagram, YouTube channel, etc.).`
);
export const FAQ_5_CATEGORY = Locale.of("faq-5-category", `{ faq-category-service }`);
export const FAQ_5_TITLE = Locale.of("faq-5-title", `Report`);
export const FAQ_5_TEXT = Locale.of(
  "faq-5-text",
  `Artists'Card and Artists'Card Studio impose sanctions on any action that violates the Terms of Use.<br />
You can report it through the Report or Contact Us buttons. The app manager reviews the report description before responding to it,<br />
but sometimes the response may be unavoidably delayed depending on the description.<br />
<br />
The content for which the reason for report is valid may be deleted or converted to private without prior notice, and the following content falls within the scope of reporting.<br />
- Infringement of copyright or neighboring rights<br />
- Profanity or sexual contents<br />
- Unauthorized advertising contents<br />
- Other contents that are contrary to the Terms of Use`
);
export const FAQ_6_CATEGORY = Locale.of("faq-6-category", `{ faq-category-service }`);
export const FAQ_6_TITLE = Locale.of("faq-6-title", `Conditions of Sign-up and Content Upload for Artists'Card Studio`);
export const FAQ_6_TEXT = Locale.of(
  "faq-6-text",
  `Any artists wishing to make songs can sign up on Artists'Card Studio.<br />
All uploaded contents go through a review process. If any contents fall into any of the following categories as a result of the review, the contents may be converted to private (service unavailable).<br />
<br />
- Infringement of copyright or neighboring rights (Original Singer's Official track or contents of other creators)<br />
- Profanity or sexual contents<br />
- Unauthorized advertising contents<br />
- Low quality track or noise <br />
- Other contents that are contrary to the Terms of Use`
);
export const FAQ_7_CATEGORY = Locale.of("faq-7-category", `{ faq-category-function }`);
export const FAQ_7_TITLE = Locale.of("faq-7-title", `Can I download content?`);
export const FAQ_7_TEXT = Locale.of(
  "faq-7-text",
  `For each contents, Artists'Card pays royalties to the original song’s copyright holder for streaming through the Korea Music Copyright Association.<br />
Accordingly, note that the download function is not provided.`
);
export const FAQ_8_CATEGORY = Locale.of("faq-8-category", `{ faq-category-exchange }`);
export const FAQ_8_TITLE = Locale.of("faq-8-title", `What is the Payout?`);
export const FAQ_8_TEXT = Locale.of(
  "faq-8-text",
  `Payout refers to “Converting Gifts Received from Listeners into Cash.”<br />
If you have 1,000 or more gifts, you can apply for payout. (If you have less than 1,000 gifts, you cannot apply for payout.)`
);
export const FAQ_9_CATEGORY = Locale.of("faq-9-category", `{ faq-category-exchange }`);
export const FAQ_9_TITLE = Locale.of("faq-9-title", `Payout Method and Deposit Schedule (ROK)`);
export const FAQ_9_TEXT = Locale.of(
  "faq-9-text",
  `1. Conditions for Applying for Payout<br />
\\- If you have 1,000 or more gifts<br />
\\- If you have never violated the Terms of Use and Operation Policy of Artists'Card<br />
<br />
2. How to Apply for Payout<br />
Login to <a href=""https://studio.artistscard.com"" target=""_blank"">https://studio.artistscard.com</a>, tab Login {'>'} Settlement {'>'} Payout<br />
<br />
3. Required Documents for Payout<br />
- Payout application form (for identity verification): Name/E-mail/Address/Mobile number<br />
- Identity document (ID card): Name/The 13-digit resident registration number (for tax return)<br />
- Account information with the same name as the ID card (copy of bankbook): Account holder name/Account number/Bank name/Official seal of the bank (for deposit)<br />
<br />
4. Payout Receiving Schedule<br />
- The deadline for required documents for payout is every 20th day of each month based on KST, and the money will be deposited on the 1st day of the next month (by 23:59, KST).<br />
- The expected deposit date may be subject to change due to national holidays or internal circumstances of Artists'Card.<br />
<br />
5. Other Information<br />
- Payout is classified as personal income, and a tax shall be reported, accordingly.
- For tax report, identity documents that can identify the name of the income tax reporter (creator) and the 13-digit resident registration number are required.<br />
- The required documents for payout shall be submitted every time when you apply for payout.<br />
- If the account information is different from the documents, the payout may be restricted.<br />
- In the case of minors, payout is possible through their legal representative. The legal representative shall submit a document proving such power, together.`
);
export const FAQ_10_CATEGORY = Locale.of("faq-10-category", `{ faq-category-exchange }`);
export const FAQ_10_TITLE = Locale.of("faq-10-title", `Payout Method and Deposit Schedule (Global)`);
export const FAQ_10_TEXT = Locale.of(
  "faq-10-text",
  `1. Conditions for Applying for Payout<br />
\\- If you have 1,000 or more gifts<br />
\\- If you have never violated the Terms of Use and Operation Policy of Artists'Card<br />
<br />
2. How to Apply for Payout<br />
Login to <a href=""https://studio.artistscard.com"" target=""_blank"">https://studio.artistscard.com</a>, tab Login {'>'} Settlement {'>'} Payout<br />
<br />
3. Required Documents for Payout<br />
- Payout application form (for identity verification): Name/E-mail/PayPal Account/Nationality (Permanent residency)/Address/Mobile number<br />
- Identity document (ID card): Name/Unique number of the ID card<br />
<br />
4. Payout Receiving Schedule<br />
- The deadline for required documents for payout is every 20th day of each month based on KST, and the money will be deposited on the 1st day of the next month (by 23:59, KST).<br />
- The expected deposit date may be subject to change due to national holidays of Korea or internal circumstances of Artists'Card.<br />
<br />
5. Other Information<br />
- The unit currency for payout is US dollar ($).<br />
- For payout, overseas remittance and payout fees are deducted.<br />
- The required documents for payout shall be submitted every time when you apply for payout.<br />
- If the account information is different from the documents, the payout may be restricted.<br />
- In the case of minors, payout is possible through their legal representative. The legal representative shall submit a document proving such power, together.`
);
export const FAQ_11_CATEGORY = Locale.of("faq-11-category", `{ faq-category-exchange }`);
export const FAQ_11_TITLE = Locale.of("faq-11-title", `Payout and Copyright Fees`);
export const FAQ_11_TEXT = Locale.of(
  "faq-11-text",
  `Artists'Card Gift includes royalties for making contents and commission fees in the App store.<br />
When exchanging gifts for cash, a creator can receive a settled amount excluding copyright fee (10.5%), App store fee (30%), and payout fee (4.5%).<br />
<br />
[Example] When applying for payout of 1,000 gifts,<br />
- Copyright fee (10.5 %): KRW 10,500<br />
- App store fee (30 %) : KRW 30,000<br />
- Payout fee (4.5 %): KRW 4,500<br />
- Payout (settlement) amount: KRW 55,000 (3.3 % withholding tax included)`
);
export const FAQ_12_CATEGORY = Locale.of("faq-12-category", `{ faq-category-service }`);
export const FAQ_12_TITLE = Locale.of("faq-12-title", `Can I use the studio with the Artists'Card app account?`);
export const FAQ_12_TEXT = Locale.of(
  "faq-12-text",
  `If you follow the procedure below, your accounts will be linked and you can use both services with one account.<br />
1. When logging into the app via a studio account<br />
You can log in to the app with your studio account and use its services.<br />
We recommend artists use the app and studio via your studio account.<br />
2. When logging into the studio via an app account<br />
When you log in to the studio with an app account, a new channel shall be opened.<br />
Artists who used the same app account as your existing studio account,
are recommended to use the studio account, and to leave or not to use the app account.<br /><br />
<b>[Note!] If the studio account and the app account are the same (when signing up for email)</b><br />
For the artistes with the same studio account and app account via email, take the following procedure. Please, read the following carefully:<br />
(1) 'Change Email' is required for studio accounts. Access the link below and change the email from your existing studio account to another email.<br />
<a href=""https://studio.artistscard.com/ko/re-signup"">https://studio.artistscard.com/ko/re-signup</a><br />
You can log in to the studio and the app with the changed email.<br />
(2) We recommend that you leave or do not use your existing app account.<br /><br/>
Artists who encounter problems or have difficulties in the process of account merge,<br />
contact us by filling the email accounts of the app and studio via contact@artistscard.com. We will help you resolve them after check.<br />
Thank you.<br />`
);
export const FAQ_13_CATEGORY = Locale.of("faq-13-category", `{ faq-category-service }`);
export const FAQ_13_TITLE = Locale.of("faq-13-title", `Can I upload songs I have composed myself?`);
export const FAQ_13_TEXT = Locale.of(
  "faq-13-text",
  `Artists'Card Studio allows you to upload any tracks (have not been officially released on the music site) that are not entrusted with related rights.<br />
In other words, it is not allowed to upload original singers' tracks that are officially released and distributed,<br />
but a new track or live track created by singing or playing the song again can be serviced by uploading it with the concept of covering the song.
Note that Artists'Card pays copyright fees to the Korea Music Copyright Association for all cover songs. Thus, copyright fees are also paid for own songs not entrusted.<br />`
);
export const FAQ_14_CATEGORY = Locale.of("faq-14-category", `faq-14-category`);
export const FAQ_14_TITLE = Locale.of("faq-14-title", `Payout Method and Deposit Schedule (ROK Local)`);
export const FAQ_14_TEXT = Locale.of("faq-14-text", `faq-14-text`);
export const FAVORITE = Locale.of("favorite", `Favorite`);
export const FAVORITE_COUNT = Locale.of("favorite-count", `{ favorite }`);
export const CLAP = Locale.of("clap", `Clap`);
export const CLAP_COUNT = Locale.of("clap-count", `{ clap }`);
export const COPY_TO_CLIPBOARD = Locale.of("copy-to-clipboard", `Copied`);
export const CHANNEL_URL = Locale.of("channel-url", `Channel URL`);
export const TRACK_TITLE = Locale.of("track-title", `Original Song title`);
export const WORK_REQUEST = Locale.of("work-request", `Apply for original song`);
export const WORK_REQUEST_IDLE = Locale.of("work-request-idle", `Original song registration is being requested.`);
export const WORK_REQUEST_ADD = Locale.of("work-request-add", `Add original songs`);
export const CONTENT_TO_BE_PUBLIC = Locale.of(
  "content-to-be-public",
  `Are you sure you want to make it public? The content is made public in the Artist's Card app.`
);
export const CONTENT_TO_BE_PRIVATE = Locale.of(
  "content-to-be-private",
  `Are you sure you want to make it private? The content is not made public in the Artist's Card app.`
);
export const DOCUMENT_DESCRIPTION = Locale.of("document-description", `Music social audio platform`);
export const DOCUMENT_KEYWORD_LIST = Locale.of(
  "document-keyword-list",
  `cover,cover song,cover music,artist,kpop,audio,social,platform,streaming,online,live,concert`
);
export const SUCCESS_UPDATE_PROFILE = Locale.of("success-update-profile", `The image has been changed.`);
export const ERROR_CONTENT_TITLE_LENGTH = Locale.of("error-content-title-length", `Enter up to 100 characters for a content title.`);
export const SUCCESS_DELETE = Locale.of("success-delete", `The deletion is completed.`);
export const PODCAST = Locale.of("podcast", `Live Replay`);
export const PODCAST_LIST_HEADING = Locale.of("podcast-list-heading", `Modify { podLive Replay }`);
export const PODCAST_EDIT_HEADING = Locale.of("podcast-edit-heading", `Modify { podLive Replay }`);
export const AUDIO_SOURCE = Locale.of("audio-source", `Track`);
export const SELF_COMPOSED_WORK = Locale.of("self-composed-work", `Own Song`);
export const ERROR_WHILE_LOADING_SELF_COMPOSITION_WORK = Locale.of(
  "error-while-loading-self-composition-work",
  `An error occurs while loading own song information.`
);
export const ALREADY_ADD_SELF_COMPOSITION_WORK = Locale.of("already-add-self-composition-work", `An own song is already added.`);
export const SELF_COMPOSED_WORK_WARNING = Locale.of(
  "self-composed-work-warning",
  `<b>I confirm that I have the copyright of the track to upload and that this music is my own song.</b><br />
If this track infringes the copyright of the third party, I'm fully responsible for all disputes arising, and the content may be deleted in accordance with the Terms of Use.`
);
export const CANNOT_SAME_PASSWORD = Locale.of(
  "cannot-same-password",
  `The new password is the same as the current one. Enter a new one, again.`
);
export const WORK_KIND_WORK = Locale.of("work-kind-work", `{ work }`);
export const WORK_KIND_REQUEST = Locale.of("work-kind-request", `{ work-request }`);
export const ERROR_EMPTY_WORK = Locale.of("error-empty-work", `Enter all the original songs.`);
export const ERROR_EMPTY_WORK_REQUEST = Locale.of("error-empty-work-request", `Enter the artist and the original song for registration.`);
export const TOOLTIP_WORK_REQUEST_STATUS_IDLE = Locale.of(
  "tooltip-work-request-status-idle",
  `The application for original song registration is not completed.`
);
export const TOOLTIP_WORK_REQUEST_STATUS_DONE = Locale.of(
  "tooltip-work-request-status-done",
  `The original song is registered.
Please, register the original song in the content for settlement of profits.`
);
export const TOOLTIP_WORK_REQUEST_STATUS_REJECT = Locale.of(
  "tooltip-work-request-status-reject",
  `The application for original song registration is rejected.`
);
export const SIGNIN_DESCRIPTION = Locale.of(
  "signin-description",
  `Artists'Card Studio and the Artists'Card mobile app need a separate account.`
);
export const FAQ_15_CATEGORY = Locale.of("faq-15-category", `{ faq-category-normal }`);
export const FAQ_15_TITLE = Locale.of("faq-15-title", `How do you rank the Artists'Card charts?`);
export const FAQ_15_TEXT = Locale.of(
  "faq-15-text",
  `The streaming that composes the music ranking is reflected once a day (once for 24 hours) for each ID.`
);
export const ACTION_REPORT = Locale.of("action-report", `Report`);
export const ACTION_REPORT_SUCCESS = Locale.of("action-report-success", `The report is received.`);
export const ALREADY_REPORTED_REASON = Locale.of("already-reported-reason", `This reason is already reported.`);
export const CONTENT_CREATE_HEADING_SELECT_KIND = Locale.of("content-create-heading-select-kind", `Select Upload Type`);
export const PLACEHOLDER_INPUT_TRACK = Locale.of("placeholder-input-track", `Please, enter the song name.`);
export const SPOKEN_LANGUAGE = Locale.of("spoken-language", `Main Language`);
export const TOOLTIP_CONTENT_SPOKEN_LANGUAGE = Locale.of(
  "tooltip-content-spoken-language",
  `Enter the main language used in the Live dialog.`
);
export const PLACEHOLDER_INPUT_SPOKEN_LANGUAGE = Locale.of(
  "placeholder-input-spoken-language",
  `Enter the main language used in the Live dialog.`
);
export const ERROR_EMPTY_SPOKEN_LANGUAGE = Locale.of("error-empty-spoken-language", `Enter the main language used in the Live dialog.`);
export const CAST_EDIT_HEADING = Locale.of("cast-edit-heading", `Live Replay Edit`);
export const ERROR_WHILE_LOADING_CAST = Locale.of("error-while-loading-cast", `An error occurs while loading the Live Replay`);
export const ERROR_CAST_TITLE_LENGTH = Locale.of("error-cast-title-length", `Enter up to 100 characters for a title.`);
export const SUCCESS_UPDATE_CAST = Locale.of("success-update-cast", `The Live Replay information is modified.`);
export const CAST_UPDATE = Locale.of("cast-update", `Update Live Replay`);
export const CAST_UPDATING = Locale.of("cast-updating", `Live Replay Updating...`);
export const CAST_UPDATE_ERROR = Locale.of("cast-update-error", `Failed to update the Live Replay.`);
export const CAST_UPDATED = Locale.of("cast-updated", `The Live Replay update is completed.`);
export const ADD_SPOKEN_LANGUAGE = Locale.of("add-spoken-language", `Add Main Language`);
export const REMOVE_SPOKEN_LANGUAGE = Locale.of("remove-spoken-language", `Delete main language`);
export const NOTICE_MAIN = Locale.of("notice-main", `Notice`);
export const TYPE = Locale.of("type", `Type`);
export const DATE_OF_CREATION = Locale.of("date-of-creation", `Date`);
export const CORRECT_ENTER_CANNOT_LOGIN = Locale.of("correct-enter-cannot-login", `You entered it correctly, but can't log in?`);
export const ADDITIONAL_INFO = Locale.of("additional-info", `Enter Additional Information`);
export const SETUP_COMPLETE = Locale.of("setup-complete", `Setting Completed`);
export const LINK_APP_STUDIO_INFO = Locale.of(
  "link-app-studio-info",
  `This process is to interlock the Artists'Card app and the studio accounts. Please, enter additional information.`
);
export const ENTER_NEW_EMAIL = Locale.of("enter-new-email", `Enter a new e-mail.`);
export const ACCOUNT_SETUP_COMPLETE = Locale.of("account-setup-complete", `The account setting is completed.`);
export const LINK_APP_STUDIO_NEW_EMAIL = Locale.of(
  "link-app-studio-new-email",
  `When the Artists'Card app account and the studio account are interlocked, emails are overlapped. Thus, a new email is needed. After the setting is completed, you can log into the system with a new email account.`
);
export const NOTICE_CATEGORY_NOTICE = Locale.of("notice-category-notice", `Notice`);
export const DO_NOT_MACH_LOGIN_INFO = Locale.of("do-not-mach-login-info", `The login information does not match.`);
export const DO_NOT_LOOK_AGAIN = Locale.of("do-not-look-again", `Don't show this message again.`);
export const CANNOT_UPLOAD_FILE_OVER300MB = Locale.of("cannot-upload-file-over300mb", `Up to 300 MB files can be uploaded.`);
export const CANNOT_UPLOAD_TRACK_OVER30MB = Locale.of(
  "cannot-upload-track-over30mb",
  `Files of larger than 30 MB can be uploaded only for Live Replay.`
);
export const ACCOUNT_DELETE = Locale.of("account-delete", `Delete Account`);
export const DEACTIVE = Locale.of("deactive", `Unsubscribe`);
export const READ_ALL_TERMS = Locale.of("read-all-terms", `I agree to the above all description.`);
export const ACCOUNT_DELETE_INFO_IMMEDIATELY = Locale.of(
  "account-delete-info-immediately",
  `If you leave the system, the personal and usage information will be immediately deleted.`
);
export const ACCOUNT_DELETE_GIFT_REFUND = Locale.of(
  "account-delete-gift-refund",
  `For the coins purchased but not used, Please request the refund before leaving.`
);
export const ACCOUNT_DELETE_CANNOT_EXCHANGE = Locale.of(
  "account-delete-cannot-exchange",
  `Payout is unavailable if you leave the system while the payout is not completed (before payout amount is not deposited).`
);
export const ACCOUNT_DELETE_CANNOT_RECOVER = Locale.of(
  "account-delete-cannot-recover",
  `The account information deleted after leaving the system cannot be restored.`
);
export const ACCOUNT_DELETE_SURE = Locale.of(
  "account-delete-sure",
  `If you leave this system, your personal and usage information will be deleted and will not be restored. Are you sure you want to leave this system?`
);
export const STUDIO_ACCOUNT_DELETE = Locale.of("studio-account-delete", `Leave Studio`);
export const THANKS_FOR_USE_STUDIO = Locale.of("thanks-for-use-studio", `Thank you for visiting our studio.`);
export const CONFIRM_DELETE_IMAGE = Locale.of("confirm-delete-image", `Are you sure you want to delete the image?`);
export const REMOVED = Locale.of("removed", `Deleted`);
export const DELETE_FAILED = Locale.of("delete-failed", `Failed to delete it.`);
export const ACCOUNT_DELETE_FAILED = Locale.of("account-delete-failed", `Failed to leave the system.`);
export const SETTLEMENT_REQUEST = Locale.of("settlement-request", `Payout application`);
export const LOGIN_ACCOUNT_GOOGLE = Locale.of("login-account-google", `Google login account`);
export const LOGIN_ACCOUNT_FACEBOOK = Locale.of("login-account-facebook", `Facebook login account.`);
export const LOGIN_ACCOUNT_APPLE = Locale.of("login-account-apple", `Apple login account`);
export const APP_INTERWORKING_NOTICE = Locale.of("app-interworking-notice", `Guide to Interlock with Artists'Card App Account`);
export const NOTICE_USE_BOTH_APP_WEB = Locale.of(
  "notice-use-both-app-web",
  `With one account, you can use both the Artists'Card app and the Artists'Card Studio.`
);
export const NOTICE_CONTEXT_1 = Locale.of(
  "notice-context-1",
  `When using the studio with the app account you used, register the channel name and agree to the Terms of Use. Then, you can use it with the same account.`
);
export const NOTICE_CONTEXT_2 = Locale.of("notice-context-2", `What if the App and studio accounts are the same?`);
export const NOTICE_CONTEXT_3 = Locale.of(
  "notice-context-3",
  `You can log in to the App account in the same way. If you want to use a studio account, set a new email.`
);
export const NOTICE_CONTEXT_4 = Locale.of("notice-context-4", `For more information, visit FAQ.`);
export const GO_TO_LOGIN = Locale.of("go-to-login", `Go to Login`);
export const SIGN_IN_WITH_GOOGLE = Locale.of("sign-in-with-google", `Sign In with Google`);
export const SIGN_IN_WITH_FACEBOOK = Locale.of("sign-in-with-facebook", `Sign In with Facebook`);
export const SIGN_IN_WITH_APPLE = Locale.of("sign-in-with-apple", `Sign In with Apple`);
export const GO_TO_SETUP_EMAIL = Locale.of("go-to-setup-email", `Go to set a new email`);
export const PHONE_NUMBER = Locale.of("phone-number", `Phone Number`);
export const EXCHANGE_GIFT = Locale.of("exchange-gift", `Gift for Payout`);
export const NO_HYPHEN = Locale.of("no-hyphen", `Please enter hyphen without.`);
export const HIGH_QUALITY_IMAGES_RECOMMENDED = Locale.of(
  "high-quality-images-recommended",
  `It is recommended to use high quality images.`
);
export const MAXIMUM_SETTLEABLE_AMOUNT_GIFT = Locale.of(
  "maximum-settleable-amount-gift",
  `Up to { $settleableAmount } gifts can be exchanged.`
);
export const IMAGE = Locale.of("image", `Image`);
export const ADD_IMAGE_FILE = Locale.of("add-image-file", `Add Image File`);
export const ERROR_CHECK_GIFT = Locale.of(
  "error-check-gift",
  `The number of gifts shall range from { $minimumSettleableAmount } to { $exchangeCoin }.`
);
export const NO_IMAGE = Locale.of("no-image", `Please add an image.`);
export const CANNOT_CHANGE_SAME_EMAIL = Locale.of("cannot-change-same-email", `You cannot change to the same email.`);
export const USE_EMAIL = Locale.of("use-email", `Enter your e-mail.`);
export const REQUIRED_INPUT = Locale.of("required-input", `This item is required.`);
export const MINIMUM_IMAGE = Locale.of("minimum-image", `One or more images`);
export const ERROR_REQUIRED_INFORMATION = Locale.of("error-required-information", `Not all essential information is entered.`);
export const ERROR_WHILE_EXCHANGE = Locale.of("error-while-exchange", `Payout Failed`);
export const SUCCESS_CREATE_EXCHANGE = Locale.of("success-create-exchange", `Payout Succeeded`);
export const RESET_EMAIL = Locale.of("reset-email", `Set Email`);
export const CANT_LOG_EXISTING_ACCOUNT = Locale.of("cant-log-existing-account", `Are you unable to log in with your studio account?`);
export const CREATE_NEW_EMAIL_ORDER = Locale.of(
  "create-new-email-order",
  `If the 'studio' account and the 'app' account are the same (when signing up for email), artists need to take the following procedure:

(1) Change the existing 'studio' account email to a new one.
With the new email, use the studio and its services. 

(2) We recommend that you leave or do not use your existing 'app' account.

See the notice for details.`
);
export const SUBMIT = Locale.of("submit", `Register`);
export const ERROR_CHANNEL_NAME_LENGTH_GREATER = Locale.of(
  "error-channel-name-length-greater",
  `Enter below 25 characters for a channel name.`
);
export const DONATION_CONTENT = Locale.of("donation-content", `Content`);
export const DONATION_KINDS = Locale.of("donation-kinds", `Type`);
export const TOOLTIP_EXCHANGE_IMAGE = Locale.of(
  "tooltip-exchange-image",
  `Required documents for payout
- Payout application form (for identity verification): Name/E-mail/Address/Mobile number
- Identity document (ID card): Name/The 13-digit resident registration number (for tax return)
- Account information with the same name as the ID card (copy of bankbook): Account holder name/Account number/Bank name/Official seal of the bank (for deposit)`
);
export const SELECT_COUNTRY = Locale.of("select-country", `Select Nation`);
export const PLEASE_SELECT_COUNTRY = Locale.of("please-select-country", `Please select a country.`);
export const KOREA = Locale.of("korea", `Korea`);
export const DOMESTIC_BUSINESS = Locale.of("domestic-business", `Local businessperson`);
export const GLOBAL = Locale.of("global", `Global`);
export const FOR_IDENTIFICATION = Locale.of("for-identification", `For identification`);
export const REAL_NAME = Locale.of("real-name", `Name (real name)`);
export const PLEASE_ENTER_NAME = Locale.of("please-enter-name", `Please enter the name.`);
export const ENTER_CORRECT_EMAIL = Locale.of(
  "enter-correct-email",
  `If there is a problem, we will contact you via this email. Please, enter the correct email.`
);
export const ENTER_ADDRESS = Locale.of("enter-address", `Please, enter the address.`);
export const ENTER_RESIDENT_ADDRESS = Locale.of("enter-resident-address", `Enter the address of your resident registration.`);
export const EXCHANGE_NOTICE_1 = Locale.of(
  "exchange-notice-1",
  `1. Identity document (ID card): ‘For tax report, your name and the 13 digits of your resident registration number shall be shown.’\n2. Account information with the same name as the ID card (copy of bankbook): ‘For deposit, the account holder name, account number, bank name, and official seal of the bank shall be shown.’\n`
);
export const EXCHANGE_NOTICE_2 = Locale.of(
  "exchange-notice-2",
  `- Payout is classified as personal income, and a tax shall be reported, accordingly.
- For tax report, identity documents that can identify the name of the income tax reporter (creator) and the 13-digit resident registration number are required.
- The required documents for payout shall be submitted every time when you apply for payout.
- If the account information is different from the document, the exchange may be restricted.
- In case of minors, they will change currency through their legal representative. Submit the document proving the legal representative.`
);
export const EXCHANGE_REQUIRE_DOCUMENT = Locale.of("exchange-require-document", `Required Documents for Payout`);
export const BUSINESS_NAME = Locale.of("business-name", `Business name`);
export const ENTER_BUSINESS_NAME = Locale.of("enter-business-name", `Enter the business name.`);
export const BUSINESS_LICENSE_NUMBER = Locale.of("business-license-number", `Business registration number`);
export const ENTER_BUSINESS_LICENSE_NUMBER = Locale.of("enter-business-license-number", `Enter the business registration number.`);
export const REPRESENTATIVE_NAME = Locale.of("representative-name", `Representative name`);
export const ENTER_REPRESENTATIVE_NAME = Locale.of("enter-representative-name", `Enter representative name.`);
export const BUSINESS_ADDRESS = Locale.of("business-address", `Business address`);
export const ENTER_BUSINESS_ADDRESS = Locale.of("enter-business-address", `Enter the business address.`);
export const SETTLEMENT_MANAGER_NAME = Locale.of("settlement-manager-name", `Settlement Officer Name`);
export const ENTER_SETTLEMENT_MANAGER_NAME = Locale.of("enter-settlement-manager-name", `Please, enter the settlement office name.`);
export const SETTLEMENT_MANAGER_PHONE_NUMBER = Locale.of("settlement-manager-phone-number", `Settlement officer phone number`);
export const ENTER_SETTLEMENT_MANAGER_PHONE_NUMBER = Locale.of(
  "enter-settlement-manager-phone-number",
  `Enter settlement officer phone number.`
);
export const SETTLEMENT_MANAGER_EMAIL = Locale.of("settlement-manager-email", `Settlement officer e-mail`);
export const ENTER_SETTLEMENT_MANAGER_EMAIL = Locale.of("enter-settlement-manager-email", `Enter settlement officer email.`);
export const NATIONALITY = Locale.of("nationality", `Nationality`);
export const ENTER_NATIONALIY = Locale.of("enter-nationaliy", `Enter your nationality.`);
export const ENTER_PHONE_NUMBER = Locale.of("enter-phone-number", `Enter your phone number.`);
export const EXCHANGE_NOTICE_GLOBAL_1 = Locale.of(
  "exchange-notice-global-1",
  `1. Identity document (ID card): ‘The name and unique number of the ID card shall be shown.’\n`
);
export const EXCHANGE_NOTICE_GLOBAL_2 = Locale.of(
  "exchange-notice-global-2",
  `- The unit currency for payout is US dollar ($).
- For payout, overseas remittance and payout fees will be deducted.
- The required documents for payout shall be submitted every time when you apply for payout.
- If the account information is different from the documents, the payout may be restricted.
- In the case of minors, payout is possible through their legal representative. The legal representative shall submit a document proving such power, together.`
);
export const EXCHANGE_NOTICE_BUSINESS_2 = Locale.of(
  "exchange-notice-business-2",
  `- After checking the required documents for payout, we request an electronic tax invoice.
- As the amount of payout does not include VAT, the electronic tax invoice needs to include VAT.
(e.g. If the amount is KRW 1 million, a tax invoice shall be at KRW 1.1 million including VAT.)
- The required documents for payout shall be submitted every time when you apply for payout.
- If the account information is different from the documents, the payout may be restricted.`
);
export const EXCHANGE_NOTICE_BUSINESS_1 = Locale.of(
  "exchange-notice-business-1",
  `1. Business license (business registration certificate)\n2. Business account information (copy of bankbook)`
);
export const LISTENERS = Locale.of("listeners ", `Listeners`);
export const INFO = Locale.of("info", `Info.`);
export const LISTENER_LIST = Locale.of("listener-list", `Listener List`);
export const BROADCAST_TITLE = Locale.of("broadcast-title", `Live Streaming Title`);
export const BROADCAST_ANNOUNCEMENT = Locale.of("broadcast-announcement", `Live Streaming Notice`);
export const START_BROADCAST = Locale.of("start-broadcast", `Live Streaming Start`);
export const ENTER_TITLE_MAX25 = Locale.of("enter-title-max25", `Enter the title (up to 25 characters).`);
export const ENTER_ANNOUNCEMENT_MAX50 = Locale.of("enter-announcement-max50", `Enter a notice (up to 50 characters).`);
export const SONG_REQUEST_COUNT = Locale.of("song-request-count", `[ { $count } ] songs requested`);
export const ADD_SONG_REQUEST = Locale.of("add-song-request", `Add Song Request`);
export const SEARCH_ORIGINAL_SONG = Locale.of("search-original-song", `Search Original Song`);
export const REGISTERING = Locale.of("registering", `Register`);
export const GIFT_DISPLAY_HERE = Locale.of("gift-display-here", `Your likes, followers, and gifts are displayed here.`);
export const START_BROADCAST_FIRST = Locale.of("start-broadcast-first", `Please, start the live streaming, first.`);
export const ENTER_MESSAGE = Locale.of("enter-message", `Enter your message.`);
export const END_BROADCAST = Locale.of("end-broadcast", `Live Streaming End`);
export const LOADING_BROADCAST = Locale.of("loading-broadcast", `Live Streaming Loading...`);
export const ASK_END_BROADCAST = Locale.of("ask-end-broadcast", `Are you sure you want to end the live streaming?`);
export const ENTER_ALL_BROADCAST_INFO = Locale.of("enter-all-broadcast-info", `Enter all live streaming information.`);
export const ASK_START_BROADCAST = Locale.of(
  "ask-start-broadcast",
  `Are you sure you want to start streaming live streaming?\nLive streaming time is Max 3 hours.
Live Replay will not be generated for broadcasts less than 5 minutes.
(However, if you were playing copyrighted music while broadcasting, you must not pubilc it.)`
);
export const UNABLE_START_BROADCAST = Locale.of("unable-start-broadcast", `You cannot start live streaming.`);
export const GIFT_RANK = Locale.of("gift-rank", `Gift Rank`);
export const ASK_USER_EXPEL = Locale.of("ask-user-expel", `Are you sure you want to export the user?`);
export const NEWS_FEED = Locale.of("news-feed", `News Feed`);
export const FOLLOW_ME = Locale.of("follow-me", `Follow me`);
export const PRESENT_GIFT = Locale.of("present-gift", `Give { $amount } gifts`);
export const ADD = Locale.of("add", `Add`);
export const GET_READY_BROADCAST = Locale.of("get-ready-broadcast", `Live Streaming Preparing...`);
export const UNKNOWN_USER = Locale.of("unknown-user", `Unknown user`);
export const HAS_SENT_COINT = Locale.of("has-sent-coint", ` has sent coin(s)!`);
export const FREEZE_CHAT = Locale.of("freeze-chat", `Freeze Chat Room`);
export const HAS_JOINED_CHAT = Locale.of("has-joined-chat", ` has joined.`);
export const CANNOT_MODIFY_SONG_DURING_BROADCAST = Locale.of(
  "cannot-modify-song-during-broadcast",
  `You cannot edit the list of song requests during live streaming.`
);
export const ALREADY_EXIST_SONG = Locale.of("already-exist-song", `This song already exists.`);
export const ENTER_SONG_INFO = Locale.of("enter-song-info", `Please Enter the information on the song.`);
export const SONG_REQUEST_GIFT = Locale.of("song-request-gift", `Donate { $amount } gifts`);
export const SONG_REQUEST_MESSAGE = Locale.of("song-request-message", `{ $name } has donated { $count } coin(s) to "{ $song }" song. `);
export const MICROPHONE = Locale.of("microphone", `Microphone`);
export const SELECT_MICROPHONE = Locale.of("select-microphone", `Select a microphone.`);
export const AUTOSCROLL_OFF = Locale.of("autoscroll-off", `Auto scrolling is OFF.`);
export const BROADCAST_START = Locale.of("broadcast-start", `The live streaming starts.`);
export const BROADCAST_END = Locale.of("broadcast-end", `The live streaming ends.`);
export const CANNOT_LOGOUT_ON_BROADCAST = Locale.of("cannot-logout-on-broadcast", `You cannot log out the system during live streaming.`);
export const BROADCAST_WILL_END_SOON = Locale.of("broadcast-will-end-soon", `The live streaming will end soon.`);
export const BROADCAST_END_DUE_NETWORK = Locale.of("broadcast-end-due-network", `The live streaming ends due to a network error.`);
export const GO_TO_CREATE_CHANNEL = Locale.of("go-to-create-channel", `Create Channel`);
export const VIEW_STUDIO_GUIDE = Locale.of("view-studio-guide", `View Studio Guide`);
export const STREAM = Locale.of("stream", `Live Streaming`);
export const EMPTY_CHAT = Locale.of("empty-chat", `Empty Chat Room`);
export const NOT_SUPPORT_MOBILE_BROADCAST = Locale.of(
  "not-support-mobile-broadcast",
  `Live Streaming function is not supported on mobile studio. 
Access via studio.artistscard.com/live on a PC.`
);
export const NOTIFICATION = Locale.of("notification", `Alert`);
export const BROADCAST_SCREEN_NOTI = Locale.of(
  "broadcast-screen-noti",
  `When you are not looking at the live streaming screen,
if a listener joins, an admission notification is sent to you.`
);
export const BROWSER_NOT_SUPPORT_NOTI = Locale.of("browser-not-support-noti", `This browser does not support alerts.`);
export const ENTER_BROADCAST_TITLE = Locale.of("enter-broadcast-title", `Enter the live streaming title.`);
export const REGISTER_THUMBNAIL = Locale.of("register-thumbnail", `Please, register a thumbnail.`);
export const PLACEHOLDER_TEXTAREA_CONTENT = Locale.of(
  "placeholder-textarea-content",
  `Please write your questions.
Inquiries:


*If you fill out the information below, it may help to quickly identify and solve the problem.
Also, if you upload a captured image when an error occurs, we may be able to check it more quickly.
Thank you.

1. Your operating system of computer: ex. Windows10, Mac OS High Sierra, Mojave.. ect.
2. Browser: Chrome, Safari, Firefox, Internet Explorer Edge.., etc.
3. [Additional details for live inquiry]
&nbsp;&nbsp;&nbsp;&nbsp;- Microphone type:
&nbsp;&nbsp;&nbsp;&nbsp;- Audio interface & Whether to support loopback: ex. Device name & Loopback Y`
);
export const QUESTION_REGISTER = Locale.of("question-register", `Submit`);
export const PLACEHOLDER_NO_QUESTION = Locale.of("placeholder-no-question", `Enter your question.`);
export const SUCCESS_QUESTION_CREATE = Locale.of("success-question-create", `Your question is successfully registered.`);
export const ASK_QUESTION = Locale.of("ask-question", `Ask Question`);
export const MODIFY_QUESTION = Locale.of("modify-question", `Modify Question`);
export const ERROR_WHILE_LOADING_QUESTION_INFORMATION = Locale.of(
  "error-while-loading-question-information",
  `Failed to load the question information.`
);
export const NO_QUESTION = Locale.of("no-question", `No questions`);
export const NUMBER = Locale.of("number", `Number`);
export const SUCCESS_QUESTION_MODIFY = Locale.of("success-question-modify", `Your question is successfully modified.`);
export const QUESTION_MODIFY = Locale.of("question-modify", `Modify Question`);
export const QUESTION_LIST = Locale.of("question-list", `Question List`);
export const CONFIRM_REMOVE_QUESTION = Locale.of("confirm-remove-question", `Are you sure you want to delete the question?`);
export const ERROR_WHILE_REMOVING_QUESTION = Locale.of("error-while-removing-question", `An error occurs while deleting the question.`);
export const NEW = Locale.of("new", `New`);
export const MODIFY = Locale.of("modify", `Modify`);
export const QUESTION = Locale.of("question", `Question`);
export const ERROR_NO_COMMENT = Locale.of("error-no-comment", `Enter your comment.`);
export const ERROR_NO_REPLY = Locale.of("error-no-reply", `Enter your reply.`);
export const ERROR_WHILE_EDITING_REPLY = Locale.of("error-while-editing-reply", `An error occurs while modifying the reply.`);
export const ERROR_WHILE_REMOVING_COMMENT = Locale.of("error-while-removing-comment", `An error occurs while deleting the comment.`);
export const ERROR_WHILE_EDITING_COMMENT = Locale.of("error-while-editing-comment", `An error occurs while modifying the comment.`);
export const ERROR_WHILE_LOADING_REPLY = Locale.of("error-while-loading-reply", `An error occurs while loading the reply.`);
export const PRIVATE_MODE = Locale.of("private-mode", `Private mode`);
export const ERROR_BOARD_UPLOAD_IMAGE = Locale.of("error-board-upload-image", `Do not accept images larger than 2MB.`);
export const WANT_INVITED_FOLLOWERS = Locale.of(
  "want-invited-followers",
  `Would you like to send broadcast notifications to your followers?`
);
export const TRANSFER_FAILED_RETRY = Locale.of("transfer-failed-retry", `Failed to send it. Please, try it again after a while.`);
export const SENT_SUCCESSFULLY = Locale.of("sent-successfully", `Sent`);
export const FOLLOWER_LIST = Locale.of("follower-list", `Follower List`);
export const SEARCH_FOLLOWERS = Locale.of("search-followers", `Search Follower`);
export const INVITE = Locale.of("invite", `Invite`);
export const DONE = Locale.of("done", `Done`);
export const FULL_INVITATION = Locale.of("full-invitation", `Invite All`);
export const INVITATION_COMPLETED = Locale.of("invitation-completed", `Invite Completed`);
export const TRY_AGAIN_SOON = Locale.of("try-again-soon", `Please, try it again after a while.`);
export const ASK_CLEAR_ALL_BAN = Locale.of("ask-clear-all-ban", `Are you sure you want to cancel the ban of the users?`);
export const BAN_USER_LIST = Locale.of("ban-user-list", `Banned User List`);
export const CLEAR = Locale.of("clear", `Cancel`);
export const CLEAR_ALL_BAN = Locale.of("clear-all-ban", `Cancel All`);
export const NO_BAN_USER = Locale.of("no-ban-user", `There is no banned user.`);
export const BROADCAST_TITLE_ABSOLUTELY = Locale.of("broadcast-title-absolutely", `* Enter the live streaming title.`);
export const ADMIN = Locale.of("admin", `Administrator`);
export const USER_LIVE_BROADCAST = Locale.of("user-live-broadcast", `{ $name }'s Live Streaming (max: 30 char)`);
export const RECOMMEND_IMAGE_RATIO = Locale.of("recommend-image-ratio", `Recommend an image with a ratio of 16:9.`);
export const FREQUENTLY_ASKED_QUESTIONS = Locale.of("frequently-asked-questions", `Q&A`);
export const AUTO_INVITE = Locale.of("auto-invite", `Auto Invite`);
export const AUTO_INVITE_TOOLTIP = Locale.of("auto-invite-tooltip", `After the live streaming starts, send a notification to followers.`);
export const ANSWER = Locale.of("answer", `Answer`);
export const GIFT_KIND = Locale.of("gift-kind", `Gift Type`);
export const GREETING = Locale.of("greeting", `Greetings`);
export const ENTER_GREETING = Locale.of("enter-greeting", `Please enter your greetings. (Up to 30 characters)`);
export const GREETING_EXAMPLE = Locale.of("greeting-example", `ex. Welcome :) Please follow and likes!`);
export const ERROR_CHANNEL_NAME_LENGTH = Locale.of("error-channel-name-length", `Channel name must be between 2 and 25 characters long.`);
export const INFO_CANNOT_CHANGE_DURING_BROADCAST = Locale.of(
  "info-cannot-change-during-broadcast",
  `The above information cannot be changed during the live streaming.`
);
export const ENTER_ARTIST_NAME = Locale.of("enter-artist-name", `Please enter the artist name.`);
export const SEARCH_ARTIST_NAME = Locale.of("search-artist-name", `Search Artist Name`);
export const LIKE_COUNTS = Locale.of("like-counts", `Likes`);
export const COUNT_LOGIN_LISTENERS = Locale.of("count-login-listeners", `Count of login listeners`);
export const COUNT_UNLOGIN_LISTENERS = Locale.of("count-unlogin-listeners", `Count of un-login listeners`);
export const FROZEN_CHAT_TOOLTIP = Locale.of("frozen-chat-tooltip", `The chat room is frozen. Listeners can't send chat to me!`);
export const DASHBOARD_LIVE_TEXT_1 = Locale.of(
  "dashboard-live-text-1",
  `Right now<br />
On-Air <b>live streaming</b> and Let's communicate with your fans!<br /><br />`
);
export const DASHBOARD_LIVE_TEXT_2 = Locale.of(
  "dashboard-live-text-2",
  `If your live streaming for the first time<br />
Let's read the studio guide below!<br />`
);
export const DASHBOARD_LIVE_TEXT_3 = Locale.of(
  "dashboard-live-text-3",
  `Hello.<br />
 Here is <b>Artists'Card studio</b>.<br />
Welcome all the artists.<br /><br />`
);
export const DASHBOARD_LIVE_TEXT_4 = Locale.of(
  "dashboard-live-text-4",
  `Right now, On-Air <b>live streaming</b> and<br /> Let's communicate with your fans!`
);
export const CLEAR_CHAT_CONFIRM = Locale.of("clear-chat-confirm", `Are you sure you want to clear the chats?`);
export const BROADCAST_TIME = Locale.of("broadcast-time", `Total Airtime`);
export const HIGHEST_LISTENERS = Locale.of("highest-listeners", `Highest listeners`);
export const ACCMULATED_LISTENERS = Locale.of("accmulated-listeners", `Accumulated listeners`);
export const ACCMULATED_GUESTS = Locale.of("accmulated-guests", `Accumulated guests`);
export const CLAP_MESSAGE = Locale.of("clap-message", `👏  { $name } applauds to you. 👏`);
export const FOLLOW_MESSAGE = Locale.of("follow-message", `💜  { $name } has followed. 💜`);
export const BROADCAST_DEFAULT_TITLE = Locale.of("broadcast-default-title", `{ $name }'s Live Streaming`);
export const LIVE_REPLAY = Locale.of("live-replay", `Live Replay`);
export const ERROR_REDO_EXCHANGE = Locale.of(
  "error-redo-exchange",
  `If there is a case in progress on the PayOut application, you can new apply after it is completed.`
);
export const PLEASE_ALLOW_AUDIO_DEVICE = Locale.of(
  "please-allow-audio-device",
  `The microphone permission should be allowed to start the live streaming. 
Please check your browser settings.`
);
export const CAST_BUTTON_PRIVATE = Locale.of("cast-button-private", `Switch Live Replay as 'private' `);
export const CAST_BUTTON_SHORTCUSTS = Locale.of("cast-button-shortcusts", `Go to the Live Replay`);
export const CAST_CONFIRM_PRIVATE = Locale.of("cast-confirm-private", `Would you like to switch the Live Replay to private?`);
export const CAST_PRIVATE_ERROR = Locale.of("cast-private-error", `Failed to switch the Live Replay as private.`);
export const SUCCESS_UPDATE_CAST_PRIVATE = Locale.of("success-update-cast-private", `The Live Replay has been switched to private.`);
export const CAST_REPLAY_PRIVATE_TEXT = Locale.of(
  "cast-replay-private-text",
  `Live Replay of live streaming that have just ended are automatically saved.
However, Live Replay will not be generated for broadcasts less than 5 minutes.`
);
export const LOCAL_TITLE = Locale.of("local-title", `Title`);
export const GLOBAL_TITLE = Locale.of("global-title", `Engilsh Title`);
export const UPDATE_LOCAL_TITLE = Locale.of("update-local-title", `Modify Title`);
export const ADD_GLOBAL_TITLE = Locale.of("add-global-title", `Add Engilsh Title`);
export const UPDATE_GLOBAL_TITLE = Locale.of("update-global-title", `Modify Engilsh Title`);
export const TOOLTIP_CONTENT_LOCAL_TITLE = Locale.of(
  "tooltip-content-local-title",
  `Enter the name of artist who created the content and the cover song.
This is the title that will be shown to users 
who speak the same language as the artist uploading the content.`
);
export const TOOLTIP_CONTENT_GLOBAL_TITLE = Locale.of(
  "tooltip-content-global-title",
  `Enter the name of artist who created the content and the cover song.
This is the title that will be shown to users 
who speak the other language as the artist uploading the content.`
);
export const ERROR_NO_LOCAL_TITLE = Locale.of("error-no-local-title", `Enter the Title.`);
export const ERROR_NO_GLOBAL_TITLE = Locale.of("error-no-global-title", `Enter the English Title.`);
export const ERROR_CONTENT_LOCAL_TITLE_LENGTH = Locale.of(
  "error-content-local-title-length",
  `Please write content title with 100 characters or less.`
);
export const ERROR_CONTENT_GLOBAL_TITLE_LENGTH = Locale.of(
  "error-content-global-title-length",
  `Please write the english title of content with 100 characters or less.`
);
export const PLACEHOLDER_CONTENT_GLOBAL_TITLE = Locale.of("placeholder-content-global-title", `BTS-ON Piano cover (by. Brown)`);
export const CONFIRM_CONTENT_GLOBAL_TITLE = Locale.of(
  "confirm-content-global-title",
  `English titles are designated as Sung by { $username } at { $time }.`
);
export const CONTENT_GLOBAL_TITLE = Locale.of("content-global-title", `Sung by { $username } at { $time }`);
export const COPY_SHARE_LINK = Locale.of("copy-share-link", `The link to be shared has been copied.`);
export const SUPER_CHAT = Locale.of("super-chat", `Super Chat`);
export const BROADCAST_DEFAULT_GLOBAL_TITLE = Locale.of("broadcast-default-global-title", `{ $username }'s Live Streaming`);
export const BROADCAST_GLOBAL_TITLE = Locale.of("broadcast-global-title", `Broadcast Title - English`);
export const USER_LIVE_BROADCAST_GLOBAL = Locale.of("user-live-broadcast-global", `{ $username }'s Live Streaming (max: 30 char)`);
export const NETWORK_UNSTABLE = Locale.of("network-unstable", `The network connection is unstable.`);
export const REDIRECT_BROADCAST = Locale.of("redirect-broadcast", `Redirecting to broadcast...`);
export const NETWORK_ERROR_END_BROADCAST = Locale.of(
  "network-error-end-broadcast",
  `The broadcast has been ended for failed a network connection.`
);
export const RECORDING_LIVE_REPLAY = Locale.of("recording-live-replay", `Wait for a while and a Live Replay will be created.`);
export const TIMER_AUTO_CLOSE = Locale.of("timer-auto-close", `Closed after { $timer } seconds`);
export const NOTICE_CONTENT_1 = Locale.of("notice-content-1", `Hello. This is Artists’Card.`);
export const NOTICE_CONTENT_2 = Locale.of(
  "notice-content-2",
  `During 백아의 우리가 머문밤’s live broadcast on May 11th pm 11pm KST a service failure occurred due to sudden excessive traffic.
We sincerely apologize to 백아 for preparing the broadcast and to the fans of 백아 who came to listen.
Artists’Card will do its best to develop so that such an issue does not occur again.`
);
export const NOTICE_CONTENT_3 = Locale.of("notice-content-3", `We look forward to your continued interest and support. Thank you.`);
export const SOCIAL_INFO = Locale.of("social-info", `SNS Info.`);
export const TRY_ADD_PROFILE_DESCRIPTION = Locale.of("try-add-profile-description", `Try to introduce my channel!`);
export const SAVE_SUCCESS = Locale.of("save-success", `Saved successfully.`);
export const MR_DETAIL = Locale.of("mr-detail", `MR Info.`);
export const REPLAY_DETAIL = Locale.of("replay-detail", `Replay Info.`);
export const CHECK_CORRECT_PATH = Locale.of("check-correct-path", `Click the icon to verify the correct path!`);
export const MR_EDIT = Locale.of("mr-edit", `MR Edit.`);
export const ERROR_WHILE_LOADING_MR = Locale.of("error-while-loading-mr", `An error occurs while loading the MR.`);
export const ILLIGAL_ACTION = Locale.of("illigal-action", `Policy Violations`);
export const SUCCESS_UPDATE_MR = Locale.of("success-update-mr", `The Live Replay information is modified.`);
export const MR_UPDATE = Locale.of("mr-update", `Update MR`);
export const MR_UPDATING = Locale.of("mr-updating", `Updating MR...`);
export const MR_UPDATE_ERROR = Locale.of("mr-update-error", `Failed to update the MR.`);
export const MR_UPDATED = Locale.of("mr-updated", `The MR update is completed.`);
export const WORK_REQUEST_REGISTER = Locale.of("work-request-register", `Up to 100 songs can be registered.`);
export const CANNOT_REGISTER_FILE = Locale.of("cannot-register-file", `This file cannot be registered.`);
export const LOADING = Locale.of("loading", `Loading...`);
export const ENTER_VALID_LINK = Locale.of("enter-valid-link", `Please enter a valid link.`);
export const ERROR_IMAGE_SIZE_EXCHANGE = Locale.of("error-image-size-exchange", `The total attachment size must be less than 10MB.`);
export const PAYPAL_EMAIL = Locale.of("paypal-email", `PayPal Account`);
export const ENTER_CORRECT_PAYPAL_EMAIL = Locale.of(
  "enter-correct-paypal-email",
  `Please enter your PayPal account correctly in email format we will deposite to you.
Because we plan to deposit money to this PayPal account when we payout money.`
);
export const TOTAL_IMAGE_SIZE = Locale.of("total-image-size", `Total Volume of image`);
export const ERROR_WHILE_LOADING_NOTIFICATION = Locale.of(
  "error-while-loading-notification",
  `An error occurs while loading Notification.`
);
export const READ_MORE = Locale.of("read-more", `See more`);
export const NO_NOTIFICATION = Locale.of("no-notification", `There are no notification.`);
export const NOTIFICATION_CARD_HEADING = Locale.of("notification-card-heading", `Notification`);
export const LIVE_TIP = Locale.of("live-tip", `LIVE Tip!`);
export const RADIO = Locale.of("radio", `Radio`);
export const VIDEO = Locale.of("video", `Video`);
export const LIVE_INFO = Locale.of("live-info", `Live Information`);
export const LIVE_TYPE = Locale.of("live-type", `Live Type`);
export const EXTRA_SETTING = Locale.of("extra-setting", `Extra Setting`);
export const PREVIEW_VIDEO = Locale.of("preview-video", `Preview (Video Only)`);
export const LIVE_INFO_CHANGE = Locale.of("live-info-change", `Change Live info`);
export const RESET = Locale.of("reset", `Reset`);
export const STREAMKEY_TIP_1 = Locale.of("streamkey-tip-1", `Including the authentication information, broadcast on stream.`);
export const STREAMKEY_TIP_2 = Locale.of("streamkey-tip-2", `Do not exposure during the broadcast, please change it frequently.`);
export const STREAMKEY_TIP_3 = Locale.of("streamkey-tip-3", `Please be careful not to leak it.`);
export const CHECK_STREAMKEY = Locale.of("check-streamkey", `Check StreamKey`);
export const STREAMKEY = Locale.of("streamkey", `StreamKey`);
export const STREAM_SET_ONLY_VIDEO = Locale.of("stream-set-only-video", `Stream Setting (Video Only)`);
export const INVALIDATED_PAYPAL_EMAIL_FORMAT = Locale.of(
  "invalidated-paypal-email-format",
  `The email format for your PayPal account is invalid.`
);
export const NO_PAYPAL = Locale.of("no-paypal", `Enter your PayPal Account.`);
export const CHAT = Locale.of("chat", `Chat`);
export const LIST_VIEW = Locale.of("list-view", `View List`);
export const LIVE_PREPARE = Locale.of("live-prepare", `Live Preparation`);
export const LIVE_PREPARE_TIP_1 = Locale.of("live-prepare-tip-1", `1. Please adjust the thumbnail to 16:9 ratio as possible!`);
export const LIVE_PREPARE_TIP_2 = Locale.of(
  "live-prepare-tip-2",
  `2. If you register request songs, listeners can give to song a present.`
);
export const LIVE_PREPARE_TIP_3 = Locale.of("live-prepare-tip-3", `3. Private mode can only be entered as invitations.`);
export const LIVE_UPPER = Locale.of("live-upper", `LIVE`);
export const LIVE_TIP_1 = Locale.of("live-tip-1", `1. Invite your followers with the Invite button!`);
export const LIVE_TIP_2 = Locale.of("live-tip-2", `2. You can return to the modify page with the upper right button.`);
export const LIVE_TIP_3 = Locale.of("live-tip-3", `3. After modifying the information, please press the change information button.`);
export const LIVE_TIP_4_1 = Locale.of("live-tip-4-1", `4. For more information, please see`);
export const HERE = Locale.of("here", `here`);
export const LIVE_TIP_4_2 = Locale.of("live-tip-4-2", `!`);
export const DO_INVITE = Locale.of("do-invite", `Invite`);
export const CHANGE_INFO = Locale.of("change-info", `Change Info.`);
export const MOVE_LIVE = Locale.of("move-live", `Move Live`);
export const SUNDAY = Locale.of("Sunday", `Sunday`);
export const MONDAY = Locale.of("monday", `Monday`);
export const TUESDAY = Locale.of("tuesday", `Tuesday`);
export const WEDNESDAY = Locale.of("wednesday", `Wednesday`);
export const THURSDAY = Locale.of("thursday", `Thursday`);
export const FRIDAY = Locale.of("friday", `Friday`);
export const SATURDAY = Locale.of("saturday", `Saturday`);
export const TODAY = Locale.of("today", `Today`);
export const AIRTIME = Locale.of("airtime", `Airtime`);
export const CALENDAR = Locale.of("calendar", `Manage live Event`);
export const LIVE_STREAMING_START_TIME = Locale.of("live-streaming-start-time", `Live Start Time`);
export const CALENDAR_CREATE = Locale.of("calendar-create", `Register Event`);
export const CALENDAR_UPDATE = Locale.of("calendar-update", `Edit Event`);
export const SUCCESS_UPDATE = Locale.of("success-update", `The event has been changed.`);
export const SUCCESS_SCHEDULE_CREATE = Locale.of("success-schedule-create", `The Event has been registered.`);
export const CONFIM_SCHEDULE_DELETE = Locale.of(
  "confim-schedule-delete",
  `Do you want to delete the event?
it won't be restored again.`
);
export const ERROR_REQUIRE_SCHEDULE_TITLE = Locale.of("error-require-schedule-title", `Please enter an schedule title.`);
export const ERROR_REQUIRE_SCHEDULE_CONTENT = Locale.of("error-require-schedule-content", `Please enter the schedule details.`);
export const ERROR_REQUIRE_SCHEDULE_START_DATE = Locale.of("error-require-schedule-start-date", `Please enter the broadcast start time.`);
export const CONFIRM_SCHEDULE_UPDATE = Locale.of("confirm-schedule-update", `Do you want to edit your schedule?`);
export const NETWORK_RECONNECTED = Locale.of("network-reconnected", `The network has been reconnected.`);
export const ENTER_SCHEDULE_TITLE_MAX50 = Locale.of("enter-schedule-title-max50", `Please enter an event title. (up to 50 characters).`);
export const ENTER_SCHEDULE_CONTENT_MAX200 = Locale.of(
  "enter-schedule-content-max200",
  `Please enter the schedule details. (up to 200 characters).`
);
export const ERROR_SCHEDULE_TITLE_LENGTH = Locale.of("error-schedule-title-length", `The schedule title must be within 50 characters.`);
export const ERROR_SCHEDULE_CONTENT_LENGTH = Locale.of(
  "error-schedule-content-length",
  `The schedule details must be within 50 characters.`
);
export const TOOLTIP_PROFILE_GENRE = Locale.of(
  "tooltip-profile-genre",
  `Choose a genre to introduce yourself.
- Up to 3 genres can be selected. 
- The selected genre is exposed to channel information.`
);
export const USER_COUNT = Locale.of("user-count", `{ $count } People`);
export const CALENDAR_DESCRIPTION = Locale.of(
  "calendar-description",
  `You can control the broadcasting event by 'Manage live Event'. 
1. How to register
&nbsp;- Create: Choose the desired date from the calendar, enter and save each entry in the popup
&nbsp;- Edit: Click the registered item, enter and save
2. End registered, fans can subscribe for notifications of artist's event on the app.
3. The fans will receive a broadcasting notification 3 minutes before the said event.
4. Fans can enter the broadcasting by clicking [Join a Room] button at registered event when broadcasting start by clicking [Live Streaming Start] button on time. `
);
export const CURRENCY_EXCHANGE_HISTORY = Locale.of("currency-exchange-history", `PayOut history`);
export const EXCHANGE_SERIAL = Locale.of("exchange-serial", `Serial number of PayOut`);
export const EXCHANGE_TOTAL_GIFT = Locale.of("exchange-total-gift", `Total number of coins`);
export const EXCHANGE_REQUESTED_GIFT = Locale.of("exchange-requested-gift", `Number of requested coins`);
export const EXCHANGE_EXCHANGED_GIFT = Locale.of("exchange-exchanged-gift", `Number of PayOut coins`);
export const PAYMENT = Locale.of("payment", `Payment`);
export const EXCHANGE_DATE = Locale.of("exchange-date", `Date of application`);
export const PAYMENT_DATE = Locale.of("payment-date", `Payment date`);
export const REJECT = Locale.of("reject", `Reject`);
export const APPROVAL = Locale.of("approval", `Approval`);
export const PROGRESS = Locale.of("progress", `Progress`);
export const STATE = Locale.of("state", `State`);
export const NO_EXCHANGE_PAGE = Locale.of("no-exchange-page", `There is no PayOut history.`);
export const ERROR_EXCHANGE_PAGE = Locale.of("error-exchange-page", `An error occurred while loading the PayOut history.`);
export const ACCOUNT_DELETE_NO_SAME_CHANNEL = Locale.of(
  "account-delete-no-same-channel",
  `Cannot use with the same channel name for a certain period of time.`
);
export const CAN_MODIFY_BLOW = Locale.of("can-modify-blow", `You can modify it immediately below.`);
export const CAMERA_CONNECTION = Locale.of("camera-connection", `Camera connection`);
export const MOVE = Locale.of("move", `Move`);
export const LIVE_RELEASE_DATE = Locale.of("live-release-date", `Live release date/time`);
export const LIVE_PRIVATE_DATE = Locale.of("live-private-date", `Live Private Date/Time`);
export const PUBLIC_EXHIBITION = Locale.of("public-exhibition", `public exhibition`);
export const CONCERT = Locale.of("concert", `Concert`);
export const ERROR_WHILE_LOADING_CONCERT = Locale.of("error-while-loading-concert", `An error occurred while loading the concert.`);
export const NO_CONCERT_LIST = Locale.of("no-concert-list", `There are no concerts to hold`);
export const BEFORE_CONCERT = Locale.of("before-concert", `before the concert`);
export const AFTER_CONCERT = Locale.of("after-concert", `after the concert`);
export const DURING_CONCERT = Locale.of("during-concert", `during a concert`);
export const GO_TO_ARTISTSCARD = Locale.of("go-to-artistscard", `Go to Artists'Card`);
export const PREVIEW_VIDEO_ONLY = Locale.of("preview-video-only", `Preview (Video Only)`);
export const CHAT_OPEN_NEW_WINDOW = Locale.of(
  "chat-open-new-window",
  `The chat is open in a new window and there is nothing to display..!`
);
export const LIVE_END = Locale.of("live-end", `LIVE END`);
export const HOLD_MOMENT = Locale.of("hold-moment", `Just a moment, please.`);
export const CREATE_REPLAY_END_BROADCAST = Locale.of("create-replay-end-broadcast", `Creating replays and ending broadcast.`);
export const TICKET_ONLY_ONE = Locale.of("ticket-only-one", `Tickets can only be redeemed once`);
export const FAIL_TO_END_LIVE = Locale.of("fail-to-end-live", `Failed to end live.`);
export const RETRY = Locale.of("retry", `Retry`);
export const REFRESH = Locale.of("refresh", `Refresh`);
export const NETWORK_NOT_SMOOTH = Locale.of("network-not-smooth", `The network is not smooth.`);
export const CHECK_AND_TRY_AGAIN = Locale.of("check-and-try-again", `Please check and try again.`);
export const UNCONVERTED_COINS = Locale.of("unconverted-coins", `Number of unconverted coins`);
export const NOT_USED_LOGGED_OUT = Locale.of(
  "not-used-logged-out",
  `It has been logged out because it has not been used for a long time. Please log in and log in again.`
);
export const WEB_NOTICE_INFO_TITLE = Locale.of("web-notice-info-title", `[Track & Cast] Service termination`);
export const WEB_NOTICE_INFO_CONTENT = Locale.of(
  "web-notice-info-content",
  `Hello. This is Artists’Card.
We would like to express our deep gratitude to our customers who have cherished and loved the Track & Cast Service so far, and we ask for your understanding that we are delivering the news that the service will be terminated.

Artists’Card App Track & Cast : ~ December 31, 2021 (Fri)
Artists’Card Studio termination & Track download period : ~ August 31, 2022 (Wed)
(For more information, please check the notice regarding termination)

Artists’Card will provide more diverse contents to customers based on concerts and music VOD services in the future, so please show a lot of support and interest.
Thank you.
`
);
export const OPEN_IMAGE = Locale.of("open-image", `Open Image`);

import { disableDevtools } from "lib/disableDevtools";
import { initializeLocalization } from "lib/localization";
import "polyfil";
import ReactDOM from "react-dom";
import { loadLocaleInformation, LocaleInformation } from "services/localeLocaleInformation";
import * as serviceWorker from "./serviceWorker";

declare const Root: HTMLDivElement;

disableDevtools();
entry();

async function entry(): Promise<void> {
  const localeInformation = await loadLocaleInformation();
  initializeLocalization(localeInformation.unwrapOr({} as LocaleInformation));

  const { App } = await import("App");
  ReactDOM.render(<App />, Root);

  serviceWorker.unregister();
}

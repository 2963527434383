import { UserToken } from "App/Routes/UserTokenManager/UserInfo";
import { configs } from "configs";
import { HttpClient } from "lib/http-client";
import { err, ok, Result } from "utils/result";

type Item = UserToken;

export const refreshToken = async (refreshToken: string): Promise<Result<Item, any>> => {
  const body = new window.URLSearchParams({
    grant_type: `refresh_token`,
    refresh_token: refreshToken,
    client_id: configs.credentials.clientId,
    client_secret: configs.credentials.clientSecret
  });
  try {
    const client = HttpClient.create({ baseUrl: configs.urls.service });
    const response = await client.post("/auth/token", body);
    const { refresh_token, access_token, token_type, expires_in } = response;
    return ok({
      refreshToken: refresh_token,
      accessToken: access_token,
      tokenType: token_type,
      expiresAt: Date.now() + expires_in * 1000
    });
  } catch (error) {
    if (navigator.onLine) {
      return err(error);
    } else {
      return err("offline");
    }
  }
};

import { immerable } from "immer";

const FREE = "F";
type FREE = typeof FREE;

const ADMIN = "A";
type ADMIN = typeof ADMIN;

const PREMIUM = "P";
type PREMIUM = typeof PREMIUM;

const MICKY = "M";
type MICKY = typeof MICKY;

type Type = FREE | PREMIUM | ADMIN | MICKY;

export class AccountTier {
  static get Free(): AccountTier {
    return new AccountTier(FREE);
  }

  static get Premium(): AccountTier {
    return new AccountTier(ADMIN);
  }

  static get Micky(): AccountTier {
    return new AccountTier(MICKY);
  }

  static from(type: string | AccountTier): AccountTier {
    if (type instanceof AccountTier) {
      return type;
    }

    switch (type) {
      case ADMIN: {
        return AccountTier.Premium;
      }
      case MICKY: {
        return AccountTier.Micky;
      }
      default: {
        return AccountTier.Free;
      }
    }
  }

  readonly [immerable] = true;

  private constructor(private readonly type: Type) {}

  get isFree(): boolean {
    return this.type === FREE;
  }

  get isAdmin(): boolean {
    return this.type === ADMIN;
  }

  get isPremium(): boolean {
    return this.type === PREMIUM;
  }

  get isMicky(): boolean {
    return this.type === MICKY;
  }

  toString(): string {
    return this.type;
  }
}

import { Fragment, ReactNode, FC, createElement } from "react";
import { Document } from "./Document";
import * as BuildinComponents from "./BuildinComponents";
import { BlockTag, Attributes, DocumentNode, InlineTag, Linebreak, Reference, Text, Variable, Whitespace } from "./DocumentNode";

type Args<T> = Record<string, T>;
type Locales = Record<string, Locale>;
type Locale = string;

export class Localization {
  static of(locales: Locales): Localization {
    return new Localization(locales);
  }

  private readonly localeSheet: Record<string, DocumentNode[]>;

  private constructor(private readonly locales: Locales) {
    this.localeSheet = Object.entries(locales).reduce((sheet, [key, value]) => {
      sheet[key] = Document.parse(value as string);
      return sheet;
    }, {} as Record<string, DocumentNode[]>);
  }

  has(key: string): boolean {
    return !!this.localeSheet[key];
  }

  message<T = any>(key: string, args: Args<T> = {}): null | string {
    if (this.has(key)) {
      return this.formatDocumentNodeArrayToMessage(this.parse(key), args, []);
    }
    return null;
  }

  raw(key: string): null | string {
    return this.locales[key] ?? null;
  }

  private formatDocumentNodeArrayToMessage<T>(nodeList: DocumentNode[], args: Args<T>, referenceList: string[]): string {
    return nodeList.map(node => this.formatMessage(node, args, referenceList)).join("");
  }

  private formatMessage<T>(node: DocumentNode, args: Args<T>, referenceList: string[]): string {
    return (
      this.formatBlockTagToMessage(node, args, referenceList) ||
      this.formatInlineTagToMessage(node, args, referenceList) ||
      this.formatReferenceToMessage(node, args, referenceList) ||
      this.formatVariableToMessage(node, args) ||
      this.formatToMessage(node, args)
    );
  }

  private formatBlockTagToMessage<T>(node: DocumentNode, args: Args<T>, referenceList: string[]): void | string {
    if (node instanceof BlockTag) {
      return this.formatDocumentNodeArrayToMessage(node.children, args, referenceList);
    }
  }

  private formatInlineTagToMessage<T>(node: DocumentNode, args: Args<T>, referenceList: string[]): void | string {
    if (node instanceof InlineTag) {
      return this.formatDocumentNodeArrayToMessage([], args, referenceList);
    }
  }

  private formatReferenceToMessage<T>(node: DocumentNode, args: Args<T>, referenceList: string[]): void | string {
    if (node instanceof Reference) {
      if (referenceList.includes(node.name)) {
        return `{ (circular-referenced) ${node.name} }`;
      }
      if (!this.has(node.name)) {
        return `{ ${node.name} }`;
      }
      return this.formatDocumentNodeArrayToMessage(this.parse(node.name), args, referenceList.concat(node.name));
    }
  }

  private formatVariableToMessage<T>(node: DocumentNode, args: Args<T>): void | string {
    if (node instanceof Variable) {
      return String(args[node.name]);
    }
  }

  private formatToMessage<T>(node: DocumentNode, args: Args<T>): string {
    return this.format(node, args);
  }

  node<T = any>(key: string, args: Args<T> = {}): null | JSX.Element {
    if (this.has(key)) {
      return <>{this.formatDocumentNodeArrayToNode(this.parse(key), args, [])}</>;
    }
    return null;
  }

  private formatDocumentNodeArrayToNode<T>(nodeList: DocumentNode[], args: Args<T>, referenceList: string[]): JSX.Element[] {
    return nodeList.map((node, index) => <Fragment key={index}>{this.formatNode(node, args, referenceList)}</Fragment>);
  }

  private formatNode<T>(node: DocumentNode, args: Args<T>, referenceList: string[]): ReactNode {
    return (
      this.formatBlockTagToNode(node, args, referenceList) ??
      this.formatInlineTagToNode(node, args) ??
      this.formatReferenceTagToNode(node, args, referenceList) ??
      this.formatToNode(node, args)
    );
  }

  private formatBlockTagToNode<T>(node: DocumentNode, args: Args<T>, referenceList: string[]): ReactNode {
    if (node instanceof BlockTag) {
      const Component = (BuildinComponents[node.name as keyof typeof BuildinComponents] as FC) ?? node.name;
      const attributes = this.pickAttribute(node.attributes, args);
      return createElement(Component, attributes, this.formatDocumentNodeArrayToNode(node.children, args, referenceList));
    }
  }

  private formatInlineTagToNode<T>(node: DocumentNode, args: Args<T>): ReactNode {
    if (node instanceof InlineTag) {
      const Component = (BuildinComponents[node.name as keyof typeof BuildinComponents] as FC) ?? node.name;
      const attributes = this.pickAttribute(node.attributes, args);
      return createElement(Component, attributes);
    }
  }

  private formatReferenceTagToNode<T>(node: DocumentNode, args: Args<T>, referenceList: string[]): ReactNode {
    if (node instanceof Reference) {
      if (referenceList.includes(node.name)) {
        return `{ (circular-referenced) ${node.name} }`;
      }
      if (!this.has(node.name)) {
        return `{ ${node.name} }`;
      }
      return this.formatDocumentNodeArrayToNode(this.parse(node.name), args, referenceList.concat(node.name));
    }
  }

  private formatToNode<T>(node: DocumentNode, args: Args<T>): string {
    return this.format(node, args);
  }

  private format<T>(node: DocumentNode, args: Args<T>): string {
    if (node instanceof Variable) {
      return args.hasOwnProperty(node.name) ? String(args[node.name]) : `{ $${node.name} }`;
    }
    if (node instanceof Text) {
      return node.value;
    }
    if (node instanceof Linebreak) {
      return "\n".repeat(node.count);
    }
    if (node instanceof Whitespace) {
      return " ".repeat(node.count);
    }
    throw new Error("unreachable!");
  }

  private parse(key: string): DocumentNode[] {
    return this.localeSheet[key] ?? Document.parse(key);
  }

  private pickAttribute<T>(attributes: Attributes, args: Args<T>): any {
    return Object.entries(attributes).reduce((record, [key, value]) => {
      if (value instanceof Variable) {
        record[key] = args[value.name];
      } else {
        record[key] = value;
      }
      return record;
    }, {} as Record<string, any>);
  }
}

import Cookies from "js-cookie";

type PrimitiveType = null | boolean | number | string | { [index: string]: PrimitiveType } | PrimitiveType[];

export const Cookie = {
  set(key: string, value: PrimitiveType): void {
    Cookies.set(key, JSON.stringify(value));
  },
  get(key: string): null | string {
    return Cookies.get(key) ?? null;
  },
  has(key: string): boolean {
    return (Cookies.get(key) ?? null) !== null;
  },
  remove(key: string): void {
    Cookies.remove(key);
  }
};

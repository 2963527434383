const createUrls = () => ({
  root: process.env.REACT_APP_URL_ROOT!,
  current: process.env.REACT_APP_URL_CURRENT!,
  coverlala: process.env.REACT_APP_URL_COVERLALA!,
  media: process.env.REACT_APP_URL_MEDIA!,
  image: process.env.REACT_APP_URL_IMAGE!,
  service: process.env.REACT_APP_URL_SERVICE!,
  language: process.env.REACT_APP_URL_LANGUAGE!,
  iosAppDownloadLink: process.env.REACT_APP_IOS_APP_DOWNLOAD_LINK!,
  androidAppDownloadLink: process.env.REACT_APP_ANDROID_APP_DOWNLOAD_LINK!,
  defaultAvatarImage: process.env.REACT_APP_DEFAULT_AVATAR_IMAGE!,
  slackWebHookUrl: process.env.REACT_APP_STUDIO_SLACK_WEB_HOOK!,
  slackWebHookQnaUrl: process.env.REACT_APP_STUDIO_SLACK_WEB_HOOK_QNA!,
  slackWebHookLiveUrl: process.env.REACT_APP_STUDIO_SLACK_WEB_HOOK_LIVE!,
  dmsAlbumUrl: process.env.REACT_APP_DMS_ALBUM_URL!
});

const createChatting = () => ({
  url: process.env.REACT_APP_CHATTING_URL!,
  region: process.env.REACT_APP_CHATTING_REGION!,
  apiKey: process.env.REACT_APP_CHATTING_API_KEY!
});

const createCloud = () => ({
  bucket: process.env.REACT_APP_CLOUD_BUCKET!,
  accessKeyId: process.env.REACT_APP_CLOUD_ACCESS_KEY_ID!,
  secretAccessKey: process.env.REACT_APP_CLOUD_SECRET_ACCESS_KEY!
});

const createCredentials = () => {
  const clientId = process.env.REACT_APP_CLIENT_ID!;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET!;
  const authorization = `Basic ${window.btoa(`${clientId}:${clientSecret}`)}`;

  return {
    clientId,
    clientSecret,
    authorization
  };
};

export const configs = {
  isProduction: process.env.REACT_APP_BUILD_MODE! === "production",
  urls: createUrls(),
  chatting: createChatting(),
  credentials: createCredentials(),
  cloud: createCloud()
};
